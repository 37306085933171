@media(max-width: 1700px) {
	.reservation-section .sticky-stick {
		left: 100px !important;
	}
}

@media(max-width: 1600px) {
	.header-menu-wrap ul li.nav-item {
		margin: 0 20px !important;
	}

	.menu-background1 {
		right: -140px !important;
	}

	.menu-background2 {
		left: -100px !important;
	}

	.reservation-section .sticky-stick {
		left: 90px !important;
	}

	.chef-background {
		left: -90px !important;
	}

	.testimonial-background {
		left: -160px !important;
	}
}

@media(max-width: 1500px) {

	.modal-content .btn-close {
		top: 0 !important;
	}

	.header-menu-wrap ul li.nav-item {
		margin: 0 18px !important;
	}

	.header-menu-wrap .search-icon {
		margin-right: 40px !important;
	}

	.menu-background1 {
		right: -160px !important;
	}

	.reservation-section .sticky-stick {
		left: 50px !important;
	}

	.home-page.hero-slider .container {
		min-height: 100vh !important;
	}
}

@media(max-width: 1440px) {
	.reservation-section .sticky-stick {
		left: 20px !important;
	}

	.header-menu-wrap ul li.nav-item {
		margin: 0 15px !important;
	}

	.header-menu-wrap .search-icon {
		margin-right: 30px !important;
	}

	.menu-background2 {
		left: -145px !important;
	}

	.section_padding.services_section {
		padding: 100px 0 70px 0 !important;
	}

	.testimonial-background {
		left: -220px !important;
	}
}

@media(max-width: 1300px) {
	.hero-slider .container .product-t {
		width: 400px !important;
		margin-top: -40px !important;
		margin-right: -40rem !important;
	}

	.header-menu-wrap ul li.nav-item {
		margin: 0 10px !important;
	}

	.header-menu-wrap .search-icon {
		margin-right: 20px !important;
	}

	.header-menu-wrap .cart-icon {
		width: 50px !important;
		height: 50px !important;
		line-height: 50px !important;
	}

	.menu-background1 {
		right: -200px !important;
	}

	.menu-background2 {
		display: none !important;
	}

	.reservation-section .sticky-stick {
		left: -50px !important;
	}

	.chef-background {
		left: -170px !important;
	}

	.testimonial-background {
		left: -300px !important;
	}
}

@media(max-width: 1230px) {
	.header-menu-wrap ul li.nav-item {
		margin: 0 8px !important;
	}

	.menu-background1,
	.chef-background {
		display: none !important;
	}

	.reservation-section .sticky-stick {
		left: -100px !important;
	}

}

@media(max-width: 1199px) {
	.footer {
		padding-bottom: 100px !important;
	}

	h2 {
		font-size: 54px !important;
		line-height: 64px !important;
	}

	.about_images .samll-img:before {
		top: -20px !important;
		left: -20px !important;
	}

	.about_images .samll-img .hover-border-bottom {
		bottom: -20px !important;
		right: 10px !important;
	}

	.reservation-data h2 {
		width: 100% !important;
	}

	.book-reservation .reservation-data {
		top: 110px !important;
	}

	.book-reservation .hero-overlay {
		height: calc(100% - 310px) !important;
	}

	.reservation-section .sticky-stick {
		bottom: 50px !important;
		left: -150px !important;
	}

	.swiper-testimonials .testimonial-shape {
		padding: 40px 40px 100px 40px !important;
	}

	.swiper-testimonials .swiper-pagination {
		bottom: 95px !important;
	}

	.swiper-button-next,
	.swiper-rtl .swiper-button-prev {
		right: -10px !important;
	}

	.swiper-button-prev,
	.swiper-rtl .swiper-button-next {
		left: -10px !important;
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: 220px !important;
	}

	.bg-testimonial {
		background-size: 100% !important;
	}

	.testimonial-section {
		padding: 60px 0 !important;
	}

	.conatct-detail:nth-child(2) {
		margin-top: 20px !important;
	}

	/***** mobile header*******/
	.cartcount {
		top: -5px !important;
		right: -3px !important;
	}

	.mobile-header {
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
		border-radius: 70px !important;
		background-color: #ffffff !important;
		/* padding: 12px 15px !important; */
	}

	.mobile-header .header-icons {
		background-color: #FEA02F !important;
		border-radius: 100px !important;
		width: 46px !important;
		height: 46px !important;
		line-height: 44px !important;
	}

	.header-icons i {
		font-size: 20px !important;
		color: #ffffff !important;
	}

	.header-search,
	.navbar-toggler,
	.header-top-info,
	.header-cart {
		display: inline-block !important;
		vertical-align: middle !important;
	}

	.navbar-toggler {
		padding: 0 !important;
		border: none !important;
		margin: 0 0 0 15px !important;
		height: auto !important;
		width: auto !important;
	}

	.navbar-toggler-icon {
		height: auto !important;
		width: auto !important;
	}

	.navbar-toggler-icon i {
		font-size: 24px !important;
		color: #1D1A25 !important;
	}

	.navbar-toggler:focus {
		box-shadow: none !important;
		outline: none !important;
	}

	.navbar-collapse {
		position: fixed !important;
		top: 0 !important;
		width: 50% !important;
		height: 100vh !important;
		min-height: 100% !important;
		background-color: #1D1A25 !important;
		text-align: left !important;
		overflow-y: auto !important;
		visibility: hidden !important;
		z-index: 9999 !important;
		-webkit-backface-visibility: hidden !important;
		box-sizing: border-box !important;
		box-shadow: -3px 0 3px rgb(0 0 0 / 4%) !important;
		-webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1) !important;
		-moz-transition: all .3s cubic-bezier(.77, 0, .175, 1) !important;
		transition: all .3s cubic-bezier(.77, 0, .175, 1) !important;
		left: -100% !important;
		visibility: visible !important;
	}

	.navbar-collapse.collapse.show {
		left: 0 !important;
		visibility: visible !important;
	}

	.navbar-nav {
		text-align: left !important;
		padding: 50px !important;
	}

	li.nav-item {
		display: block !important;
		vertical-align: middle !important;
		margin: 0 !important;
		text-align: left !important;
		width: 100% !important;
		border-bottom: 1px solid rgb(255 255 255 / 59%) !important;
	}

	li.nav-item:last-child {
		border-bottom: none !important;
	}

	.navbar-nav .nav-link {
		color: #ffffff !important;
		padding: 15px 0 !important
	}

	.close-menu {
		position: absolute !important;
		right: 20px !important;
		top: 20px !important;
		color: #fff !important;
		font-size: 24px !important;
	}

	.nav-item .dropdown-menu {
		-webkit-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		transform: none !important;
		position: initial !important;
		height: 0 !important;
		border-radius: 0 !important;
	}

	.mobile-header .nav-item .dropdown-menu {
		opacity: 0 !important;
	}

	.nav-item:hover .dropdown-menu {
		-webkit-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		transform: none !important;
	}

	.nav-item .dropdown-menu.show {
		height: auto !important;
		visibility: visible !important;
		opacity: 1 !important;
	}

	.dropdown-item {
		padding: 8px 15px !important;
	}

	.dropdown-toggle::after {
		position: absolute !important;
		right: 0 !important;
		color: #ffffff !important;
	}

	.conatct-info-header {
		padding: 0 50px !important;
	}

	.conatct-info-header h2 {
		font-size: 30px !important;
		line-height: 30px !important;
		margin-bottom: 30px !important;
		padding-bottom: 20px !important;
		border-bottom: 1px solid rgb(255 255 255 / 59%) !important;
	}

	.conatct-info-header .header-top-info {
		margin-bottom: 20px !important;
		display: block !important;
		font-weight: 400 !important;
	}

	.food-img-price img {
		width: 150px !important;
		height: 150px
	}

	.contact-us {
		padding: 50px !important;
	}

	.form-title {
		font-size: 40px !important;
		line-height: 40px !important;
	}

	.main_page .header .mobile-header {
		background-color: transparent !important;
		padding: 0 !important;
	}

	.main_page .header .mobile-header .mb-header-left {
		display: flex !important;
		align-items: center !important;
	}

	.main_page .header .mobile-header .mb-header-left .navbar-toggler {
		margin: 0 !important;
	}

	.main_page .header .navbar-toggler-icon i {
		color: #ffffff !important;
	}

	.main_page .mobile-header .search-icon {
		background-color: transparent !important;
	}

	.main_page .mobile-header .cart-icon {
		background-color: transparent !important;
		border: 1px solid #FEA02F !important;
	}

	.main_page .mobile-header .cart-icon i {
		color: #FEA02F !important;
	}

	.main_page .main-header.fixed-header .navbar-toggler-icon i {
		color: #1D1A25 !important;
	}

	.main_page .fixed-header .mobile-header .cart-icon:hover {
		background-color: #FEA02F !important;
	}

	.main_page .fixed-header .mobile-header .cart-icon:hover i {
		color: #ffffff !important;
	}

	.main-header.fixed-header .mobile-header .search-icon i {
		color: #FEA02F !important;
	}

	.facts-textr {
		font-size: 1.4rem !important;
	}

	.facts-textl {
		font-size: 1.4rem !important;
	}

	.facts-icon-box {
		width: 80px !important;
		height: 80px !important;
	}

}

@media(max-width: 1199px) and (min-width: 992px) {
	.food_item_desc {
		padding: 20px 150px 20px 20px !important;
	}

	.odd .food_item_desc {
		padding: 20px 20px 20px 150px !important;
	}

	.about_images .samll-img {
		padding-right: 30px !important;
	}

	#nutrival {
		font-size: 1.2rem !important;
	}
}

@media(max-width: 1024px) {
	.reservation-section .sticky-stick {
		display: none !important;
	}

	.blog-slider-swipe .blog-info {
		margin-right: 20px !important;
	}

	.testimonial-background {
		left: -366px !important;
	}

	.testimonial-round {
		transform: scale(1.05) !important;
	}
}

@media(min-width: 1200px) {
	.odd .food_item_desc {
		padding: 30px 30px 30px 170px !important;
	}
}

/* @media(max-width: 992px) {
	* {
		opacity: 1 !important;
	}
} */

@media(min-width: 992px) {
	.odd .food-img-price {
		left: 0 !important;
		right: auto !important;
	}

	.odd .menu-list-item {
		padding-left: 30px !important;
		padding-right: 0 !important;
	}

	.odd .food_item_desc {
		text-align: right !important;
	}
}

@media(max-width: 991.5px) {
	.big-banner.bg-product {
		background-position: right !important;
		background-size: 89rem !important;
	}

	.hero-slider .container .product-t {
		width: 400px !important;
		margin-right: 0rem !important;
	}

	.flip-card {
		margin-top: 10rem !important;
		transform: scale(1.5) !important;
	}

	.flip-card-inner {
		width: auto !important;
	}

	.flipimg {
		width: 65% !important;
		height: 65% !important;
	}

	.proimg {
		width: 65% !important;
		height: 65% !important;
	}

	.testimonial-round {
		transform: scale(1) !important;
	}

	.about_images .samll-img {
		padding-right: 30px !important;
	}

	.services_section .service-item {
		margin-bottom: 0 !important;
		padding: 20px !important;
	}

	.services_section {
		padding: 80px 0 50px 0 !important;
	}

	.main_page .about-btn.book-btn {
		margin-top: 40px !important;
	}

	.blog-section.home-blogs {
		padding: 80px 0 !important;
	}

	.services_section .service_desc {
		padding-top: 16px !important;
	}

	.main_page .about_images {
		text-align: center !important;
		padding-top: 40px !important;
	}

	.section_padding.services_section {
		padding: 80px 0 80px 0 !important;
	}

	.main_page .reservation-section .big-banner {
		top: 0 !important;
		height: 668px !important;
	}

	.swiper-testimonials .swiper-pagination {
		bottom: 510px !important;
		left: 30px !important;
	}

	.swiper-testimonials .testimonial-shape {
		margin-top: 50px !important;
		box-shadow: inset 0px 0px 6px 0px rgb(29 26 37 / 10%) !important;
		padding: 30px 30px 85px 30px !important;
	}

	.swiper-testimonials .client-image {
		margin-top: 50px !important;
	}

	.swiper-testimonials .testimonial-slider .col-12.col-lg-4 {
		text-align: center !important;
	}

	.testimonial-background {
		display: none !important;
	}

	.main_page .book-reservation .hero-overlay {
		top: 0 !important;
		height: calc(100% - 400px) !important;
	}

	.main_page .book-reservation .reservation-data {
		top: 80px !important;
		text-align: center !important;
		margin-bottom: 140px !important;
	}

	.food_menu_section .menu-list-item-5 {
		margin-bottom: 30px !important;
	}

	.section_padding.food_menu_section {
		padding: 80px 0 50px 0 !important;
	}

	.menu-nav-item {
		display: flex !important;
		flex-wrap: nowrap !important;
		overflow-x: auto !important;
		justify-content: initial !important;
		padding-bottom: 15px !important;
		margin-bottom: 40px !important;
	}

	.navbar-collapse {
		width: 70% !important;
	}

	.chef-item.layout-2 {
		margin-bottom: 80px !important;
	}

	.section_padding {
		padding: 80px 0 !important;
	}

	.custom-field {
		margin-bottom: 20px !important;
		padding: 8px !important;
	}

	.image-tab svg {
		width: 70px !important;
		height: 70px !important;
	}

	.menu-list-detail.menu-nav-item li {
		margin: 0 20px !important;
	}

	.blog-section {
		padding: 80px 0 220px 0 !important;
	}

	.pb-70 {
		padding-bottom: 50px !important;
	}

	.mt-mobile {
		margin-top: 40px !important;
	}

	.counter-section {
		padding: 100px 0 70px 0 !important;
	}

	.counter {
		margin-bottom: 30px !important;
	}

	.menu-nav-item .nav-item {
		margin: 0 8px !important;
	}

	.item-link {
		padding: 15px 24px !important;
	}

	.dish-image {
		width: 140px !important;
		height: 140px !important;
	}

	.bg-testimonial {
		background-size: cover !important;
	}

	.f_price {
		font-size: 30px !important;
		line-height: 30px !important;
	}

	.food-img-price {
		top: -60px !important;
	}

	.food_item_desc {
		margin-top: 60px !important;
		padding-right: 130px !important;
	}

	.blog-top {
		margin-bottom: 20px !important;
	}

	.blog-title {
		font-size: 30px !important;
	}

	.newsletter-heading {
		text-align: center !important;
		margin-bottom: 30px !important;
	}

	.footer {
		padding: 0 0 80px 0 !important;
	}

	.footer .col-xl-7 {
		order: 2 !important;
	}

	.footer .col-xl-5 {
		order: 1 !important;
	}

	.open-detail {
		padding: 30px !important;
		position: relative !important;
		top: 0 !important;
		right: 0 !important;
		margin-top: -50px !important;
		max-width: 500px !important;
		background-size: cover !important;
		background-position: center !important;
		margin-left: auto !important;
		margin-right: auto !important;
		margin-bottom: 50px !important;
	}

	.copyright-text {
		margin-top: 40px !important;
		text-align: center !important;
	}

	.modal-content .btn-close {
		right: -60px !important;
	}

	.search-modal-close {
		right: 15px !important;
	}

	.page-title.container {
		min-height: 450px !important;
	}

	.menu-nav-item .nav-item {
		margin: 5px !important;
	}

	.food-img-price img {
		width: 120px !important;
		height: 120px !important;
	}

	.contact-infromation {
		margin-top: 40px !important;
	}

	.map iframe {
		height: 500px !important;
	}

	.page-link {
		width: 50px !important;
		height: 50px !important;
		line-height: 48px !important;
		font-size: 22px !important;
	}

	.book-reservation .book-now h2 {
		font-size: 52px !important;
	}

	.m {
		width: 100% !important;
	}

}

@media(max-width: 768px) {

	.slider_shape_img1,
	.slider_shape_img2 {
		display: none !important;
	}

	.main_page .about-btn.book-btn {
		margin-top: 40px !important;
	}

	.main_page .book-reservation .reservation-data {
		top: 60px !important;
		margin-bottom: 100px !important;
	}

	.main_page .reservation-section .big-banner {
		height: 628px !important;
	}

}

@media(min-width: 767.5px) and (max-width: 991.5px) {
	.blog_slider_section {
		margin-top: -80px !important;
	}
}

@media(min-width: 767.5px) {
	.get-connect-border {
		border-right: 3px solid white !important;
	}

	.home-about-title span.left_line {
		width: 110px !important;
		display: inline-block !important;
		vertical-align: middle !important;
		position: relative !important;
		height: 2px !important;
		background-color: #fcfcfc !important;
	}

	.home-about-title span.right_line {
		width: 110px !important;
		display: inline-block !important;
		vertical-align: middle !important;
		position: relative !important;
		height: 2px !important;
		background-color: #fcfcfc !important;
	}

}

@media(max-width: 767.5px) {
	.home-bg {
		background-image: url("../../../public/images/drragi-m-front-banner.webp") !important;
	}

	.chef-item.layout-2 {
		width: 17rem !important;
		height: 17rem !important;
	}

	.home-about-title h3 {
		padding: 10px !important;
		font-size: 1rem !important;
	}

	.ytcook {
		height: 15rem !important;
	}

	.menu-list-detail .nav-item span {
		font-size: 18px !important;
		line-height: 18px !important;
	}

	.about-heading {
		text-align: center !important;
		display: block !important;
	}

	.featured_blog h2,
	.featured_blog>p {
		text-align: center !important;
	}

	.image-tab svg {
		width: 60px !important;
		height: 60px !important;
	}

	.image-tab {
		height: 80px !important;
	}

	.menu-list-detail.menu-nav-item li {
		margin: 0 16px !important;
	}

	.mt-60 {
		margin-top: 30px !important;
	}

	.mb-40 {
		margin-bottom: 20px !important;
	}

	.bg-testimonial {
		display: none !important;
	}

	.header-top {
		display: none !important;
	}

	.header {
		background-color: #ffffff !important;
	}

	.header-bottom {
		margin-bottom: 0 !important;
	}

	.custom-field {
		margin-bottom: 20px !important;
	}

	.modal-content .btn-close {
		right: 0 !important;
		top: -60px !important;
	}

	.modal-dialog {
		margin: 70px auto !important;
		padding: 0 15px !important;
	}

	.left_line,
	.right_line {
		width: 50px !important;
	}

	.hero-title {
		margin: 20px 0 60px 0 !important;
		font-weight: 400 !important;
		font-size: 50px !important;
		line-height: 70px !important;
	}

	.chef-slider {
		text-align: center !important;
	}

	.chef-item.layout-2 {
		margin-bottom: 50px !important;
	}

	h2 {
		font-size: 46px !important;
		line-height: 56px !important;
	}

	h3 {
		font-size: 26px !important;
		line-height: 26px !important;
	}

	.section_heading {
		margin-bottom: 30px !important;
	}

	.section_padding {
		padding: 50px 0 !important;
	}

	p {
		font-size: 18px !important;
		line-height: 26px !important;
	}

	.mb-30 {
		margin-bottom: 10px !important;
	}

	.menu-nav-item {
		margin-bottom: 36px !important;
	}

	.counter-section {
		padding: 50px 0 20px 0 !important;
	}

	.about-service {
		padding: 20px 0 0 0 !important;
	}

	.hero-slider .container {
		min-height: 570px !important;
	}

	.c_item {
		font-size: 60px !important;
		line-height: 60px !important;
	}

	.about-service li img {
		margin-right: 15px !important;
		width: 80px !important;
	}

	.food_item_desc {
		margin-top: 50px !important;
		padding: 20px 110px 20px 20px !important;
		padding-right: 110px !important;
	}

	.menu-list-item {
		padding-right: 15px !important;
	}

	.food-name {
		line-height: 20px !important;
		margin-bottom: 10px !important;
		font-size: 20px !important;
	}

	.f_price {
		padding: 8px !important;
		font-size: 24px !important;
		line-height: 24px !important;
	}

	.food-img-price {
		top: -50px !important;
	}

	.dish-image {
		width: 130px !important;
		height: 130px !important;
	}

	.pb-70 {
		padding-bottom: 20px !important;
	}

	.book-now {
		padding: 50px !important;
	}

	.service-item {
		padding: 20px 20px !important;
		margin-top: 30px !important;
	}

	.readmore-btn {
		bottom: -35px !important;
		width: 80px !important;
		height: 80px !important;
		line-height: 80px !important;
	}

	.chef-name {
		margin: 60px 0 10px 0 !important;
	}

	.master-chef .chef-item .chef-name {
		margin: 25px 0 15px 0 !important;
	}

	.swiper-pagination {
		margin-top: 40px !important;
	}

	.swiper-pagination-bullet {
		margin: 0 10px !important;
		width: 8px !important;
		height: 8px !important;
	}

	.blog-section {
		padding: 50px 0 130px 0 !important;
	}

	.blog_slider_section {
		padding: 30px 30px 0 30px !important;
		margin-top: -80px !important;
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none !important;
	}

	.blog-item .readmore-btn {
		right: 10px !important;
		width: 60px !important;
		height: 60px !important;
		line-height: 60px !important;
		bottom: -30px !important;
	}

	.blog-info {
		margin-right: 20px !important;
	}

	.blog-info span {
		font-size: 18px !important;
		line-height: 18px !important;
	}

	.blog-info span i {
		font-size: 18px !important;
		line-height: 18px !important;
		margin-right: 5px !important;
	}

	.blog-title {
		font-size: 26px !important;
		line-height: 30px !important;
		margin-bottom: 10px !important;
	}

	.subscribe-btn {
		margin-left: 20px !important;
	}

	.newsletter-heading {
		margin-bottom: 20px !important;
	}

	.testimonial-section {
		padding: 30px 0 50px 0 !important;
	}

	.testimonial-round {
		max-width: 100% !important;
		border-radius: 0 !important;
		padding: 0 !important;
		box-shadow: none !important;
	}

	.footer {
		padding: 0 0 50px 0 !important;
	}

	.copyright-text {
		margin-top: 30px !important;
		font-size: 16px !important;
	}

	.gallery-slider {
		padding: 0 15px !important;
	}

	.gallery-item.odd {
		margin-top: 15px !important;
	}

	.footer-content-top {
		display: block !important;
		text-align: center !important;
	}

	.social-share {
		text-align: center !important;
		margin-top: 20px !important;
	}

	.footer-desc {
		font-size: 16px !important;
		line-height: 26px !important;
		margin: 20px 0 !important;
		text-align: center !important;
	}

	.conatct-detail {
		margin-right: 0 !important;
	}

	.footer-info {
		text-align: center !important;
	}

	.about-heading .right_line {
		display: none !important;
	}

	.item-name {
		font-size: 20px !important;
		line-height: 20px !important;
		margin-bottom: 20px !important;
	}

	.search-form-holder {
		display: block !important;
	}

	.search-label {
		display: block !important;
		text-align: center !important;
		margin-bottom: 15px !important;
	}

	.search-field-holder {
		width: 87% !important;
		float: left !important;
		margin: 0 !important;
	}

	.search-field {
		padding: 10px 10px 0 10px !important;
	}

	.searchmodal .modal-content {
		padding: 0 0 !important;
	}

	.page-title.container {
		/* min-height: 350px  !important; */
		height: 65vh !important;
	}

	.page-desc {
		font-size: 16px !important;
	}

	.pagiantion-div {
		margin-top: 15px !important;
	}

	.page-item {
		margin: 4px !important;
	}

	.page-link {
		height: 40px !important;
		width: 40px !important;
		font-size: 18px !important;
		line-height: 38px !important;
	}

	.contact-us.book-now {
		padding: 30px !important;
	}

	.form-title {
		font-size: 30px !important;
		line-height: 30px !important;
	}

	.contact-infromation h2 {
		margin-bottom: 20px !important;
		font-size: 40px !important;
		line-height: 46px !important;
	}

	.hours-info {
		margin-top: 30px !important;
	}

	.hours-info ul li {
		padding: 15px 0 !important;
		font-size: 18px !important;
		line-height: 18px !important;
	}

	.hours-info h3 {
		margin-bottom: 10px !important;
	}

	.location-info h3 {
		margin-bottom: 20px !important;
	}

	.hours-info p {
		margin: 10px 0 0 0 !important;
	}

	.breadcrumb li {
		line-height: 22px !important;
	}

	.img-up {
		top: -35px !important;
	}

	.map iframe {
		height: 300px !important;
	}

	.video_modal .modal-dialog {
		max-width: 100% !important;
		margin: 0 !important;
	}

	.video_modal .modal-body video {
		width: 100% !important;
		height: auto !important;
	}

	.video_modal .modal-content .btn-close {
		padding: 5px !important;
	}

	.section_padding.food_menu_section {
		padding: 50px 0 20px 0 !important;
	}

	.main_page .book-reservation .reservation-data {
		top: 40px !important;
		margin-bottom: 80px !important;
	}

	.main_page .reservation-section .big-banner {
		height: 740px !important;
	}

	.section_padding.services_section {
		padding: 50px 0 !important;
	}

	.blog-section.home-blogs {
		padding: 60px 0 !important;
	}

	.blog-slider-swipe .blog-item {
		text-align: center !important;
	}

	.swiper-testimonials .testimonial-shape {
		padding: 30px 20px 80px 20px !important;
	}

	.swiper-testimonials .swiper-pagination {
		bottom: 505px !important;
		left: 15px !important;
	}

	.swiper-testimonials .client-name {
		margin: 30px 0 15px 0 !important;
	}

	.main-header.fixed-header {
		padding: 15px !important;
	}

	.main-header {
		padding: 20px 15px !important;
	}

	.main-header .container-fluid {
		padding: 0 !important;
	}

	.main-header .mb-header-center .navbar-brand img {
		width: 130px !important;
		margin-left: 10px !important;
	}

	.book-reservation .book-now h2 {
		font-size: 42px !important;
	}

	.services_section .service-item {
		padding: 15px !important;
	}

	.main_page .about-btn.book-btn {
		margin-top: 30px !important;
	}

}

@media(max-width: 575px) {
	.f-40 {
		line-height: 40px !important;
	}

	.big-banner.bg-product {
		background-size: cover !important;
	}

	.main_page .reservation-section .big-banner {
		height: 690px !important;
	}

	.main_page .about_images>img {
		width: 100% !important;
	}

	.main_page .hero-title {
		margin: 20px 0 60px 0 !important;
		font-size: 44px !important;
		line-height: 54px !important;
	}

	.navbar-collapse {
		width: 80% !important;
	}

	.service-title {
		margin: 25px 0 15px 0 !important;
	}

	.swiper-pagination-bullet {
		width: 8px !important;
		height: 8px !important;
	}

	.navbar-toggler {
		margin: 0 0 0 10px !important;
	}

	.nav-center {
		padding: 0 0 !important;
	}

	.navbar-brand img {
		width: 150px !important;
	}

	h6 {
		font-size: 18px !important;
	}

	.hero .left_line,
	.hero .right_line {
		display: none !important;
	}

	.hero-title {
		margin: 20px 0 35px 0 !important;
		font-size: 40px !important;
		line-height: 50px !important;
	}

	.book-btn {
		font-size: 18px !important;
		line-height: 18px !important;
		padding: 14px 22px !important;
	}

	.about_images>img {
		padding-right: 60px !important;
	}

	.about_images .samll-img img {
		width: 200px !important;
		height: auto !important;
	}

	.about_images .samll-img:before {
		top: -15px !important;
		left: -15px !important;
	}

	.about_images .samll-img .hover-border-bottom {
		bottom: -15px !important;
		right: 0 !important;
	}

	.left_line,
	.right_line {
		width: 30px !important;
	}

	.right_line {
		margin-left: 10px !important;
	}

	.left_line {
		margin-right: 10px !important;
	}

	.about-service li:not(:last-child) {
		margin-bottom: 20px !important;
	}

	.c_item {
		font-size: 50px !important;
		line-height: 50px !important;
	}

	.c_desc {
		font-size: 18px !important;
		line-height: 26px !important;
	}

	.about-food {
		font-size: 16px !important;
		line-height: 20px !important;
	}

	.book-now {
		padding: 30px 15px !important;
	}

	.blog_slider_section {
		padding: 15px 15px 0 15px !important;
	}

	.open-detail {
		padding: 20px !important;
	}

	.footer-time-detail {
		padding: 16px 10px !important;
	}

	.footer-time-detail h2 {
		font-size: 30px !important;
		line-height: 30px !important;
	}

	.schedule li {
		font-size: 16px !important;
		margin-bottom: 15px !important;
		line-height: 18px !important;
	}

	.schedule li span {
		margin-right: 10px !important;
	}

	.footer-time-detail a {
		font-size: 20px !important;
	}

	.about_images .samll-img {
		margin-top: -90px !important;
		padding-right: 15px !important;
	}

	.f_price {
		font-size: 18px !important;
	}

	.page_breadcrumb .breadcrumb {
		padding: 20px 15px !important;
	}

	.img-up {
		top: -29px !important;
	}

	.menuh {
		font-size: 3rem !important;
		line-height: 60px !important;
	}
}

@media(max-width: 420px) {
	.main_page .reservation-section .big-banner {
		height: 772px !important;
	}

	h2 {
		font-size: 40px !important;
		line-height: 50px !important;
	}

	.dish-image {
		width: 100px !important;
		height: 100px !important;
	}

	.food-img-price {
		top: -30px !important;
	}

	.food_item_desc {
		margin-top: 30px !important;
		padding: 15px 80px 15px 15px !important;
		padding-right: 80px !important;
	}

	.blog-item .readmore-btn {
		right: 0 !important;
		width: 40px !important;
		height: 40px !important;
		line-height: 36px !important;
		bottom: -20px !important;
	}

	.blog-item .readmore-btn svg {
		width: 18px
	}

	.navbar-nav {
		padding: 50px 20px !important;
	}

	.conatct-info-header {
		padding: 0 20px !important;
	}

	.food-img-price img {
		width: 80px !important;
		height: 80px !important;
	}

	.hours-info p i {
		font-size: 20px !important;
		margin-right: 10px !important;
	}
}

@media(max-width: 500px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 426px) !important;
	}

	.hero-slider .container .product-t h1 {
		font-size: 10vw !important;
		line-height: 2.5rem !important;
	}
}

@media(max-width: 516px) and (min-width: 500px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 426px) !important;
	}
}

@media(max-width: 431px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 482px) !important;
	}

	.facts-textl {
		text-align: start !important;
	}
}

@media(max-width: 420px) and (min-width: 324px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 376px) !important;
	}
}

@media(max-width: 376px) and (min-width: 321px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 384px) !important;
	}

	.logoh {
		width: 10rem !important;
	}
}

@media(max-width: 375px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 406px) !important;
	}

	.swiper-testimonials .swiper-pagination {
		bottom: 480px !important;
	}
}

@media(max-width: 374px) {
	.navbar-collapse {
		width: 95% !important;
	}

	.schedule li {
		line-height: 22px !important;
		margin-bottom: 10px !important;
		text-align: left !important;
	}

	.schedule li span {
		display: block !important;
	}

	.book-now {
		padding: 30px 15px !important;
	}

	h2 {
		font-size: 36px !important;
		line-height: 45px !important;
	}

	.item-link {
		font-size: 18px !important;
		line-height: 18px !important;
		padding: 16px 17px !important;
	}

	.service-title {
		margin: 15px 0 15px 0 !important;
	}

	.schedule {
		margin-bottom: 20px !important;
	}

	.about_images .samll-img {
		margin-top: -50px !important;
		padding-right: 15px
	}

	.about_images .samll-img .hover-border-bottom {
		right: 0 !important;
	}

	.header-top-info i,
	.navbar-toggler-icon i {
		font-size: 20px
	}

	.mobile-header .header-icons {
		width: 42px !important;
		height: 42px !important;
		line-height: 40px !important;
	}

	.navbar-toggler {
		margin: 0 0 0 5px !important;
	}

	.header-top-info i {
		margin-right: 5px !important;
	}

	.contact-us.book-now {
		padding: 20px 15px 20px 15px !important;
	}

	.contact-infromation h2 {
		font-size: 36px !important;
		line-height: 40px !important;
	}
}

@media(max-width: 360px) {
	.swiper-testimonials .swiper-pagination {
		bottom: 465px !important;
	}
}

@media(max-width: 320px) {
	.main_page .book-reservation .hero-overlay {
		height: calc(100% - 452px) !important;
	}

	.logoh {
		width: 10rem !important;
	}

	.swiper-testimonials .testimonial-shape {
		padding: 30px 20px 60px 20px !important;
	}

	.swiper-testimonials .swiper-pagination {
		bottom: 416px !important;
	}
}