.z-1 {
    z-index: 1 !important;
}

.mobile-header {
    padding: 5px 10px;
}

.bg-header {
    z-index: 99999;
}

.curser-pointer {
    cursor: pointer;
}

.svg-waves {
    margin: auto;
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.chocolate-r-img {
    background: url(../../../public/images/drragi-chocolate-recipe.webp);
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.elaichi-r-img {
    background: url(../../../public/images/drragi-elaichi-banner.webp);
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.dhokla-r-img {
    background: url(../../../public/images/drragi-dhokla-banner.webp);
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.pancake-r-img {
    background: url(../../../public/images/drragi-pancake-banner.webp);
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.tomato-r-img {
    background: url(../../../public/images/drragi-tomato-banner.webp);
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.malt-r-img {
    background: url(../../../public/images/drragi-malt-banner.webp);
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.about-bg {
    background-image: url('../../../public/images/drragi-subscribe-banner.webp');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    filter: brightness(0.5);
}

.home-bg {
    background-image: url('../../../public/images/drragi-about-banner.webp');
}

.contact-bg {
    background-image: url('https://www.rbs-classes.com/wp-content/uploads/2020/09/banner.jpg');
}

/* custom css */

.about_images>img {
    padding: 0;
}

.about_images {
    text-align: right;
}

@media(max-width: 991px) {
    .about_images {
        text-align: center;
        margin-top: 40px;
    }
}

@media(max-width: 767px) {
    .about_images {
        text-align: center;
        margin-top: 30px;
    }
}

.home-about-title {
    align-items: end !important;
}

.home-about-title h3 {
    background-color: #92283a;
    padding: 15px 3rem;
    border-radius: 50px;
    box-shadow: 0 0 10px 0px white;
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    font-family: 'Courgette';
}

.home-about-title span.right_line::after {
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    top: -3px;
    background-color: #ffffff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.home-about-title span.left_line::before {
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    top: -3px;
    background-color: #ffffff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.whatsapp-ico {
    fill: white;
    width: 60px;
    height: 60px;
    padding: 3px;
    background-color: #4dc247;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    /* box-shadow: 2px 2px 11px rgba(0,0,0,0.7); */
    position: fixed;
    bottom: 15px;
    right: 25px;
    z-index: 10;
}

.whatsappimg {
    width: 25px;
}

.gmailimg {
    width: 25px;
}

.abouticons {
    width: 110px;
}

.border-theme {
    /* border-color: rgba(214, 101, 95, 0.729) !important; */
    box-shadow: 0px 5px 10px 1px #e6655e55;
}

.textragi {
    color: #b04943 !important;
}

.fot a:hover {
    color: #b04943;
}

.fot a {
    color: rgb(49, 49, 49);
}

.bgragi {
    background-color: #b04943 !important;
}

.whatsapp-ico:hover {
    box-shadow: 2px 2px 11px rgb(77, 200, 0);
}

.whatsapp-ico:active {
    transform: scale(0.95);
}

.opacity-20 {
    opacity: 20% !important;
}

.opacity-50 {
    opacity: 50% !important;
}

.logoh {
    width: 15rem;
    transform: scale(1.15);
}

.bg-theme {
    background-color: #b04943;
}

.bg-pancake {
    background-color: lightsalmon;
}

.bg-tomato {
    background-color: rgb(212, 72, 72);
}

.bg-elaichi {
    background-color: #3fbd65;
}

.menuh {
    font-size: 5rem;
    opacity: 0.7;
    line-height: 100px;
    font-family: 'Courgette', cursive;
}

.nowrap {
    white-space: nowrap;
}

.nfont {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.m {
    width: 15vw;
}

.bg-pro {
    background: rgb(54, 47, 251);
    background: linear-gradient(160deg, rgba(54, 47, 251, 1) 0%, rgba(219, 133, 245, 1) 0%, rgba(232, 129, 232, 1) 26%, rgb(233, 95, 95) 50%, rgb(253, 79, 79) 63%);
}

.bgmenu {
    background-color: #27b652;
    transition: all 0.5s;
}

.iconborder {
    border-radius: 10px;
    transition: all 0.3s;
}

.iconbox {
    width: 90px;
    height: 90px;
    /* background-color: #d4d4d4; */
    border-radius: 50%;
    padding: 10px;
    margin-top: -80px;
}

.iconborder:hover {
    transform: scale(1.07);
}

.mt-80 {
    margin-top: 50px;
}

.iconbox1 {
    box-shadow: 0 10px 20px rgba(128, 128, 128, 0.588);
    transition: all 0.3s;
}

.iconbox2 {
    box-shadow: 0 10px 20px rgba(128, 128, 128, 0.588);
    transition: all 0.3s;
}

.iconbox3 {
    box-shadow: 0 10px 20px rgba(128, 128, 128, 0.588);
    transition: all 0.3s;
}

.iconbox4 {
    box-shadow: 0 10px 20px rgba(128, 128, 128, 0.588);
    transition: all 0.3s;
}

.iconbox5 {
    box-shadow: 0 10px 20px rgba(128, 128, 128, 0.588);
    transition: all 0.3s;
}

.iconbox6 {
    box-shadow: 0 10px 20px rgba(128, 128, 128, 0.588);
    transition: all 0.3s;
}

.iconborder:hover .iconbox1 {
    border: 1px solid red;
    box-shadow: 0 15px 30px #dc354693;
    animation: iconfly 1.3s infinite ease-in-out;
}

.iconborder:hover .iconbox2 {
    border: 1px solid yellow;
    box-shadow: 0 15px 30px #ffc10797;
    animation: iconfly 1.3s infinite ease-in-out;
}

.iconborder:hover .iconbox3 {
    border: 1px solid blue;
    box-shadow: 0 15px 30px #0d6dfd8d;
    animation: iconfly 1.3s infinite ease-in-out;
}

.iconborder:hover .iconbox4 {
    border: 1px solid green;
    box-shadow: 0 15px 30px #19875498;
    animation: iconfly 1.3s infinite ease-in-out;
}

.iconborder:hover .iconbox5 {
    border: 1px solid #0dcaf091;
    box-shadow: 0 15px 30px #0dcaf091;
    animation: iconfly 1.3s infinite ease-in-out;
}

.iconborder:hover .iconbox6 {
    border: 1px solid grey;
    box-shadow: 0 15px 30px #6c757d8e;
    animation: iconfly 1.3s infinite ease-in-out;
}

@keyframes iconfly {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.shopbtn {
    outline: 0;
    border: 0;
    height: 50px;
    padding: 0px;
    box-shadow: 0 0 0.5em 0 white;
    background-color: transparent;
    border: 2px solid white;
    overflow: hidden;
}

.shopbtn div {
    transform: translateY(0px);
    width: 100%;
}

.shopbtn,
.shopbtn div {
    transition: 0.6s cubic-bezier(.16, 1, .3, 1);
}

.shopbtn div span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0.75em 1.125em;
}

.shopbtn:hover {
    background-color: white;
    /* box-shadow: 0 0.25em 0.5em 0 white; */
}

.shopbtn:hover div {
    transform: translateY(-50px);
}

.shopbp {
    animation: anim 1.5s infinite linear;
}

@keyframes anim {
    0% {
        transform: translateY(-2px);
    }

    50% {
        transform: translateY(4px);
    }

    100% {
        transform: translateY(-2px);
    }
}

.shopbtn p {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
}

.shopbtn:active {
    transform: scale(0.95);
}

.menu-img {
    transition: all 0.5s;
}

.menu-img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.counter-section svg {
    position: absolute;
    left: 0;
    bottom: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.blog_section svg {
    position: absolute;
    left: 0;
    bottom: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.testi>svg {
    position: absolute;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.blogbg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-color: #9b2222;
}

/* Product detail page css */
.facts-textl {
    text-align: end;
    /* text-decoration: underline; */
    color: white;
    font-family: cursive;
    line-height: 40px !important;
    font-weight: 900;
    font-size: 30px;
    margin-right: 15px;
}

.facts-textr {
    text-align: start;
    /* text-decoration: underline; */
    color: white;
    font-family: cursive;
    line-height: 40px !important;
    font-weight: 900;
    font-size: 30px;
}

.facts-icon-box {
    width: 100px;
    height: 100px;
    padding: 10px;
    border-radius: 50%;
}

.w-90 {
    width: 80%;
}

.w-95 {
    width: 90%;
}

.facts-product {
    position: sticky;
    top: 100px;
}

#productheading {
    position: sticky;
    top: 20px;
    font-family: monospace;
    color: white;
}

#productheading2 {
    position: sticky;
    top: 60px;
    font-family: monospace;
    color: white;
}

#nutrival {
    position: sticky;
    top: 15vw;
    font-weight: bold;
    color: white;
    line-height: 2;
    font-size: 23px;
}

.nutribox {
    /* padding: 20px; */
    border-radius: 50px;
    background: white;
    box-shadow: 20px 20px 60px rgba(164, 4, 4, 0.574),
        -20px -20px 60px rgb(146, 14, 51);
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
}

#ingridval {
    position: sticky;
    top: 15vw;
    font-weight: bold;
    color: white;
    line-height: 2;
    font-size: 30px;
}

.bround {
    border-radius: 0px 0px 48px 48px;
}

#facts-box1 {
    position: sticky;
    top: 130px;
    /* transform: translateY(100px); */
    /* margin-top: 500px; */
}

#facts-box2 {
    position: sticky;
    top: 130px;
    /* transform: translateY(100px); */
    /* margin-top: 500px; */
}

.out {
    animation: out 0.5s ease-in;
    opacity: 0;
}

@keyframes out {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    65% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}


.z-1 {
    z-index: 0;
}

.shadowinw {
    box-shadow: inset 0 0 7px 4px rgba(219, 86, 3, 0.821);
}

.flipimg {
    width: 100%;
    height: 100%;
}

.relcard {
    display: flex;
    height: 380px;
    width: 280px;
    /* background-color: #e92c2c; */
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000000b7;
    /*   margin-left: -50px; */
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
    transform: rotate(-5deg);
}

.relcard:active {
    transform: scale(1) !important;
    box-shadow: -1rem 0 3rem #0000007b;
}

.brown {
    background-color: brown;
}

.lightbrown {
    background-color: burlywood;
}

.relcard:not(:first-child) {
    margin-left: -50px;
}

.relcard:hover {
    transform: translateY(-10px) rotate(0deg) scale(1.1);
    transition: 0.3s ease-out;
    cursor: pointer;
}

.relcard:hover~.relcard {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
}

.f-30 {
    font-size: 30px;
}

.f-40 {
    font-size: 40px;
}

.f-20 {
    font-size: 20px;
}

.h-w-font {
    font-family: monospace;
    color: white !important;
}

.buybtn {
    padding: 0.6em 1.8em;
    border: 3px solid white;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    border-radius: 50px;
    color: white;
}

.buybtn::before {
    content: '';
    width: 0;
    height: 2000%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: white;
    transition: .3s ease;
    display: block;
    z-index: -1;
}

.buybtn:hover::before {
    width: 105%;
}

.buybtn:hover {
    color: rgb(33, 33, 33);
    font-weight: 700;
}

.mt-n1 {
    margin-top: -18rem;
}

.z--1 {
    z-index: -1;
}

.rotate-circle {
    animation: rotate 10s infinite linear;
    width: 100%;
    opacity: 0.7;
    transform: translateY(-150px);
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.bgimg {
    background: url('../../../public/images/drragi-temp-banner.webp');
    background-size: cover;
    background-attachment: fixed;
}

.bgd {
    background-color: #00000089;
}

.slick-next {
    right: 0px !important;
}

.slick-prev {
    left: 0px !important;
}

.ytcook {
    width: 100%;
    height: 40rem;
}

.round-client {
    border-radius: 39px !important;
}

/* img flip css */
.flip-card {
    width: 350px;
    height: 420px;
    perspective: 1000px;
    transform: scale(1.15);
}

.flip-card-inner {
    position: relative;
    width: 90%;
    height: 90%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateY(180deg);
}

.flip-card-front {
    margin-top: -20px;
}

/* research */

.wrapper {
    background: rgb(116, 47, 251);
    background: linear-gradient(180deg, rgba(116, 47, 251, 1) 0%, rgba(223, 128, 227, 1) 63%, rgba(243, 143, 223, 1) 100%);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    font: normal 16px/26px Open Sans Condensed, sans-serif;
    color: #fff;
    padding: 100px 20px 200px;
    box-sizing: border-box;
}

.resicon {
    width: 70px;
    height: 70px;
    padding: 5px;
    background-color: white;
    box-shadow: 0px 0px 30px 1px rgba(255, 123, 0, 0.547);
    border-radius: 50%;
    margin-top: -50px;
    animation: fly 2s infinite alternate;
}

@keyframes fly {
    0% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(-5px);
    }
}

.rounded-50 {
    border-radius: 20px !important;
}

.res-text {
    font-size: 15px !important;
}

.block {
    z-index: 1;
    position: relative;
}

.block:before {
    content: "";
    position: absolute;
    height: calc(100% - 40px);
    width: 1px;
    background-color: #f2f2f2;
    left: 24vw;
    margin-top: 90px;
}

.title {
    font: bold 42px/52px Open Sans Condensed, sans-serif;
    text-align: center;
    padding: 20px;
    color: white;
    transition: all 0.8s ease-in-out;
}

.event-description {
    transform: scale(0.98);
}

.each-event {
    background-color: #f4f4f4;
    color: #777;
    margin: 20px auto;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.391);
    width: 250px;
    background-image: linear-gradient(#b04943, white 100%);
    position: relative;
    border-radius: 25px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    /* background: linear-gradient(to bottom, orange,lightyellow,lightyellow,lightyellow,rgb(0, 0, 0),lightyellow); */
}

.researchimg {
    height: 250px !important;
    object-fit: cover;
    object-position: center;
}

.each-event:before {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    left: -27px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 4px 20px -4px rgba(64, 64, 64, 0.8);
}

/* recipe css */
.hover-shine::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.layout-2 .blog-image:hover .hover-shine::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}

.chef-item.layout-2 {
    width: 30vw;
    height: 30vw;
}

.z9 {
    z-index: 999;
}

.product-image {
    position: relative;
}

.zoomed-image-container {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    height: 400px;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: rgb(136, 43, 43);
    border-radius: 10px;
}

/* .product-image > img:hover ~ .zoomed-image-container{
  display: block;
} */

.zoomed-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.zoomed-image>img {
    width: 700px;
    position: relative;
}

.vh-80 {
    height: 80vh;
}

.text-theme {
    color: #b04943 !important;
}

.f-icons {
    color: white !important;
    background-color: #b04943 !important;
    transition: all 0.2s;
    text-align: center;
}

footer .f-icons {
    width: 45px;
}

.dropdown-item.active {
    background-color: #b04943 !important;
}

.fit-content {
    height: fit-content;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.object-fit {
    object-fit: cover;
    object-position: center;
}


/* ---------testimonial cust-------------- */
/* ---------testimonial--------------- */

.testimonial {
    padding: 100px 0;
}

.testimonial .row .tabs {
    all: unset;
    display: grid;
    row-gap: 40px;
    justify-content: start;
    grid-template-columns: 1fr 1fr 1fr;
}

.testimonial .row .tabs li {
    all: unset;
    display: block;
}

.tabs .active img {
    border: 4px solid #b35555 !important;
    padding: 7px;
    transform: scale(1.25);
}

.tabs li div img {
    position: relative;
    z-index: 2;
    cursor: pointer;

}

.tabs li div {
    position: relative;
}

.tabs .active div::after,
.tabs .active div::before {
    opacity: 1;
    transform: translateY(0px);
}

.tabs li div::after {
    content: "";
    width: 50px;
    border-radius: 50%;
    height: 50px;
    /* margin-top: -50px; */
    left: 0px;
    top: -40px;
    transform: translateX(-100px);
    z-index: 1;
    opacity: 0;
    transition: all 0.5s;
    background-color: #9b2222;
    filter: blur(5px);
    position: absolute;
}

.swiper-slide {
    padding: 20px;
}

.bg-header {
    background-color: #F5F5F5;
    z-index: 99999;
}

.tabs li div::before {
    content: "";
    width: 100px;
    transform: translateY(100px);
    border-radius: 50%;
    height: 100px;
    /* margin-top: -50px; */
    right: 0;
    bottom: -30px;
    z-index: 2;
    opacity: 0;
    transition: all 0.5s;
    background-color: #9b2222;
    /* filter: blur(3px); */
    animation: fly 2s infinite ease-in-out;
    position: absolute;
}

.tabs li div img:hover {
    transition: all 0.5s;
    border: 4px solid #9b2222 !important;
    padding: 7px;
}

.tabs>li>.text-center>img {
    border: 5px solid #b66e66;
}

.testimonial .row .carousel>h3 {
    font-size: 20px;
    line-height: 1.45;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    margin-bottom: 0;
}

.testimonial .row .carousel h1 {
    font-size: 40px;
    line-height: 1.225;
    margin-top: 23px;
    font-weight: 700;
    margin-bottom: 0;
}

.testimonial .row .carousel .carousel-indicators {
    all: unset;
    padding-top: 43px;
    display: flex;
    gap: 10px;
    list-style: none;
}

.testimonial .row .carousel .carousel-indicators li {
    background: #000;
    background-clip: padding-box;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper {
    margin-top: 42px;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper p {
    font-size: 18px;
    line-height: 1.72222;
    font-weight: 500;
    color: rgba(0, 0, 0, .7);
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper h3 {
    color: #000;
    font-weight: 700;
    margin-top: 37px;
    font-size: 20px;
    line-height: 1.45;
    text-transform: uppercase;
}

.hover-sub:hover {
    transform: scale(1.05);
    transition: 0.5s;
    cursor: pointer;
}

.hover-sub:active {
    transform: scale(0.95);
    transition: 0.5s;
    cursor: pointer;
}

.text-elaichi {
    color: #2b9226 !important;
}

@media only screen and (max-width: 1250px) {
    .testimonial .row .tabs {
        all: unset;
        display: grid;
        row-gap: 30px;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
    }

    .servicesection {
        position: initial;
        top: 35px;
        height: auto;
    }
}

@media(min-width: 1166.5px) {
    .each-event:nth-child(odd) {
        margin-left: calc(50% + 20px);
    }

    .each-event:nth-child(even) {
        margin-left: calc(50% - 572px);
    }

    .each-event:nth-child(even):before {
        left: calc(100% + 14px);
    }

    .each-event {
        width: 550px;
        margin: -100px auto;
    }

    .block:before {
        content: "";
        position: absolute;
        height: calc(100% - 40px);
        width: 1px;
        background-color: #f2f2f2;
        left: 50%;
        margin-top: 90px;
    }

    .resdiv {
        margin-top: 150px;

    }
}

@media(min-width: 767.5px) and (max-width:1166.5px) {

    .each-event:nth-child(odd) {
        margin-left: calc(50% + 20px);
    }

    .each-event:nth-child(even) {
        margin-left: calc(50% - 372px);
    }

    .each-event:nth-child(even):before {
        left: calc(100% + 14px);
    }

    .each-event {
        width: 350px;
        margin: -100px auto;
    }

    .resdiv {
        margin-top: 150px;

    }

    .block:before {
        content: "";
        position: absolute;
        height: calc(100% - 40px);
        width: 1px;
        background-color: #f2f2f2;
        left: 50%;
        margin-top: 90px;
    }
}

@media(max-width: 629px) {
    .block::before {
        left: 0;
    }
}

@media (max-width: 992.5px) {
    .home-about-title h3 {
        font-size: 1.5rem;
    }

    #nutrival {
        top: 20vw;
    }

    #ingridval {
        top: 20vw;
    }

    #productheading2 {
        top: 85px;
    }
}

@media(min-width: 992.5px) {
    .productrow {
        height: 2800px;
    }
}

@media(max-width: 992.5px) {
    #productheading {
        font-size: 2rem;
        position: unset;
    }

    .nutribox {
        box-shadow: none;
    }

    .facts-textl {
        line-height: 25px !important;
    }

    #nutrival {
        position: unset;
        padding: .5vw;
        font-size: 20px;
        margin-top: 30px;
    }

    #ingridval {
        position: unset;
    }

    #facts-box1 {
        position: unset;
        margin-top: 10px;
    }

    #facts-box2 {
        position: unset;
        margin-top: 10px;
        margin-bottom: 30px;
    }
}