  @import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
  /*------------------------------------------------------------------
  @import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
  /*------------------------------------------------------------------

Project: Lucha Multipage Template
Last change:   12/06/2022 [fixed Float bug, vf]
Author : The_Krishna
Primary use:  Restaurants Site Template

-------------------------------------------------------------------*/

  /*------------------------------------------------------------------

                            [Table of contents]
1. Default css
2. Button
3. Animations
4. All heading
5. Header
6. Hero slider
7. About Us
8. Coutner
9. Menu Section
10. Reservation
11. Service
12. Gallery
13. Chef slider
14. Blog
15. Subscribe-Form
16. Testimonials
17. Footer
18. scroll to top button
19. Inner Page Title
20. Pagination
21. Comment Section
22. Contact US page
23. 404 page
24. Preloader
25. Scrollbar CSS 

-------------------------------------------------------------------*/

  /*------------------------ [Color codes] ------------------------
                        

Background: #ffffff 
Content:    #616F7D 
Header h1, h2 h3, h4, h5, h6:  #1D1A25  

a (standard):   #FEA02F 
a (visited):    #FEA02F 
a (hover):    #FEA02F 
a (active): #FEA02F

-------------------------------------------------------------------*/

  /************************ 1. Default css ***********************/
  body {
      background-color: #ffffff;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #616F7D;
      font-family: Outfit;
  }

  html {
      scroll-behavior: unset !important;
  }

  h1,
  h2,
  h3,
  h4 {
      font-family: Outfit;
      color: #1D1A25;
  }

  h1 {
      font-size: 60px;
      line-height: 60px;
  }

  h2 {
      font-size: 60px;
      line-height: 75px;
  }

  h3 {
      font-size: 30px;
      line-height: 30px;
  }

  h4 {
      font-size: 24px;
  }

  h5 {
      font-size: 20px;
  }

  h6 {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #b04943;
  }

  p {
      font-size: 20px;
      line-height: 150%;
      text-rendering: optimizeLegibility;
  }

  a {
      color: #b04943;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      text-decoration: none !important;
      outline: none;
  }

  ul {
      margin-bottom: 0px;
  }

  li {
      list-style: none;
  }

  .shipping li {
      list-style: inherit;
  }

  a:hover {
      color: #b04943;
  }

  .bg-light {
      background-color: #F5F5F5 !important;
  }

  #wrap {
      overflow: hidden;
      position: relative;
  }

  .container,
  .row>* {
      padding-right: 15px;
      padding-left: 15px;
  }

  .row {
      margin-right: -15px;
      margin-left: -15px;
  }

  .clearfix {
      clear: both;
  }

  .text-black {
      color: #1D1A25;
  }

  .color-primary {
      color: #FEA02F;
  }

  .section_padding {
      padding: 100px 0;
  }

  .mb-30 {
      margin-bottom: 20px;
  }

  .pb-70 {
      padding-bottom: 70px;
  }

  .mb-10 {
      margin-bottom: 10px;
  }

  .mt-60 {
      margin-top: 60px;
  }

  .mt-30 {
      margin-top: 30px;
  }

  .mb-40 {
      margin-bottom: 40px;
  }

  .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      outline: none;
      border: none;
      margin: 0;
  }

  input:focus {
      outline: none;
  }

  input:focus,
  textarea::focus {
      color: #ffffff;
  }

  input::placeholder,
  textarea::placeholder {
      color: #616F7D;
      opacity: 1;
  }

  .form-select:focus,
  textarea:focus-visible {
      border: none;
      box-shadow: none;
      outline: none;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }

  @media(min-width: 1200px) {

      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl,
      .container-xxl {
          max-width: 1200px;
      }
  }

  /*********************** 2. Button  *************************/
  .book-btn {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #1D1A25;
      padding: 18px 29px;
      border: 2px solid #FEA02F;
      border-radius: 30px;
      display: inline-block;
      background-color: #FEA02F;
      position: relative;
      overflow: hidden;
      z-index: 9;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }

  .book-btn.btn-transperent:hover {
      color: #ffffff;
  }

  .book-btn:hover {
      border-color: #ffffff;
  }

  .book-btn.btn-transperent:hover {
      border-color: #FEA02F;
  }

  .book-btn:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 100%;
      z-index: -1;
      bottom: 0;
      background-color: #ffffff;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }

  .book-btn:hover:after {
      height: 100%;
      top: 0;
      bottom: auto;
      visibility: visible;
      opacity: 1;
  }

  .book-btn.btn-transperent {
      color: #FEA02F;
      background-color: transparent;
  }

  .book-btn.btn-transperent:after {
      background-color: #FEA02F;
  }

  .bg-hover-black.book-btn:hover {
      color: #ffffff;
      border-color: #1D1A25;
  }

  .bg-hover-black.book-btn:after {
      background-color: #1D1A25;
  }

  /******************** 3. Animations *******************/

  @keyframes fixedheader {
      from {
          transform: translateY(-100%);
      }

      to {
          transform: translateY(0);
      }
  }

  @-webkit-keyframes in-top {
      from {
          transform: rotate3d(-1, 0, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @keyframes in-top {
      from {
          transform: rotate3d(-1, 0, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @-webkit-keyframes in-right {
      from {
          transform: rotate3d(0, -1, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @keyframes in-right {
      from {
          transform: rotate3d(0, -1, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @-webkit-keyframes in-bottom {
      from {
          transform: rotate3d(1, 0, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @keyframes in-bottom {
      from {
          transform: rotate3d(1, 0, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @-webkit-keyframes in-left {
      from {
          transform: rotate3d(0, 1, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @keyframes in-left {
      from {
          transform: rotate3d(0, 1, 0, 90deg);
      }

      to {
          transform: rotate3d(0, 0, 0, 0deg);
      }
  }

  @-webkit-keyframes out-top {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(-1, 0, 0, 104deg);
      }
  }

  @keyframes out-top {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(-1, 0, 0, 104deg);
      }
  }

  @-webkit-keyframes out-right {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(0, -1, 0, 104deg);
      }
  }

  @keyframes out-right {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(0, -1, 0, 104deg);
      }
  }

  @-webkit-keyframes out-bottom {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(1, 0, 0, 104deg);
      }
  }

  @keyframes out-bottom {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(1, 0, 0, 104deg);
      }
  }

  @-webkit-keyframes out-left {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(0, 1, 0, 104deg);
      }
  }

  @keyframes out-left {
      from {
          transform: rotate3d(0, 0, 0, 0deg);
      }

      to {
          transform: rotate3d(0, 1, 0, 104deg);
      }
  }

  @keyframes animate {
      0% {
          transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
      }

      100% {
          transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
      }
  }

  @-webkit-keyframes shine {
      100% {
          left: 125%;
      }
  }

  @keyframes shine {
      100% {
          left: 125%;
      }
  }

  @keyframes swing {
      0% {
          transform: rotate(3deg);
      }

      100% {
          transform: rotate(-3deg);
      }
  }

  @keyframes round-animation {
      0% {
          -webkit-transform: translateX(80px);
          -ms-transform: translateX(80px);
          transform: translateX(80px);
      }

      100% {
          -webkit-transform: translateX(0px);
          -ms-transform: translateX(0px);
          transform: translateX(0px);
      }
  }

  @keyframes animationFramesFive {
      0% {
          transform: translateY(0);
      }

      25% {
          transform: translateY(40px);
      }

      50% {
          transform: translateY(0);
      }

      75% {
          transform: translateY(-40px);
      }

      100% {
          transform: translateY(0);
      }
  }

  @-webkit-keyframes smallScale {
      from {
          transform: scale(1);
          opacity: 1;
      }

      to {
          transform: scale(1.5);
          opacity: 0;
      }
  }

  @keyframes smallScale {
      from {
          transform: scale(1);
          opacity: 1;
      }

      to {
          transform: scale(1.5);
          opacity: 0;
      }
  }

  /******************** 4. All heading *******************/
  .small_heading {
      display: inline-block;
      vertical-align: middle;
  }

  .small_heading h6 {
      display: inline-block;
      margin: 0;
  }

  .left_line,
  .right_line {
      width: 110px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      height: 2px;
      background-color: #b04943;
  }

  .left_line {
      margin-right: 20px;
  }

  .right_line {
      margin-left: 20px;
  }

  .left_line:before,
  .right_line:after {
      height: 8px;
      width: 8px;
      position: absolute;
      content: "";
      top: -3px;
      background-color: #b04943;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  }

  .left_line:before {
      left: 0;
  }

  .right_line:after {
      right: 0;
  }

  .section_heading p {
      max-width: 770px;
      margin: 0 auto;
  }

  .section_heading {
      margin-bottom: 50px;
      position: relative;
      z-index: 1;
  }

  /********************* 5. Header ***********************/
  .header {
      background-color: #F5F5F5;
      font-weight: 500;
  }

  .header-top {
      padding: 10px 0;
  }

  .header-top-info,
  .header-top-info span,
  .header-top-info a {
      font-size: 18px;
      line-height: 19px;
      vertical-align: middle;
      color: #1D1A25;
  }

  .header-top-info i {
      margin-right: 10px;
      font-size: 24px;
      display: inline-block;
      vertical-align: middle;
  }

  .header-top-info a:hover {
      color: #b04943;
  }

  .header-icons {
      width: 70px;
      height: 70px;
      background-color: #b04943;
      border-radius: 100px;
      line-height: 70px;
      text-align: center;
      position: relative;
      cursor: pointer;
  }

  .header-icons:hover {
      background-color: #b04943;
  }

  .header-icons i {
      font-size: 24px;
      color: #ffffff;
  }

  .header-icons:hover i {
      color: #ffffff;
  }

  .header-bottom {
      margin-bottom: -30px;
      position: relative;
      z-index: 999;
  }

  .main_page .cartcount {
      background-color: #b04943;
  }

  .cartcount {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #000000;
      color: #ffffff;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      border-radius: 100px;
      right: 0;
      top: 0;
  }

  .header-cart {
      position: relative;
  }

  .cart-icon {
      display: inline-block;
  }

  .cart-open p {
      color: #1D1A25;
      margin: 0;
      text-transform: capitalize;
  }

  .nav-center {
      padding: 0 20px;
  }

  .navbar-nav .nav-link {
      color: #1D1A25;
      font-size: 18px;
      line-height: 19px;
      padding: 25px 0 !important;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .nav-item:hover .nav-link,
  .navbar-nav .nav-link.active.dropdown-toggle::after,
  .navbar-nav .nav-item:hover .dropdown-toggle::after {
      color: #b04943;
  }

  li.nav-item {
      display: inline-block;
      vertical-align: middle;
      margin: 0 20px;
  }

  .header-nav-wrap {
      background-color: #ffffff;
      border-radius: 35px;
  }

  .dropdown-toggle::after {
      border: none;
      content: "\ea4e";
      font-family: 'remixicon' !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      vertical-align: middle;
      font-size: 20px;
      color: #1D1A25;
  }

  .nav-item .dropdown-menu {
      position: absolute;
      z-index: -1;
      display: block;
      margin: 0;
      text-align: left;
      -webkit-transition: .3s;
      -ms-transition: .3s;
      -moz-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      bottom: 0;
      left: 0;
      background: #ffffff;
      color: #F5F5F5;
      border: none;
      padding: 0;
      border-radius: 0;
      min-width: 220px;
      -webkit-transform: translateY(105%);
      -ms-transform: translateY(105%);
      transform: translateY(105%);
      box-shadow: 0 0 10px rgba(213, 213, 213, 0.34);
  }

  .nav-item:hover .dropdown-menu {
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      z-index: 99;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
  }

  .dropdown-item {
      padding: 10px 15px;
      clear: both;
      font-weight: 500;
      color: #1D1A25;
      border: 0;
      font-size: 16px;
  }

  .dropdown-item:hover {
      background-color: #b04943;
      color: #ffffff;
  }

  /*Sticky header ******/
  .fixed-header .header-bottom {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #ffffff;
      box-shadow: 0 0 22px -4px rgb(0 0 0 / 17%);
      -webkit-animation: fixedheader 600ms ease 0ms 1 forwards;
      animation: fixedheader 600ms ease 0ms 1 forwards;
  }

  .fixed-header .header-icons {
      width: 60px;
      height: 60px;
      line-height: 60px;
  }

  /**search modal *********/

  /********************* 6. Hero slider ***********************/
  .hero-slider {
      position: relative;
      overflow: hidden;
  }

  .home_page2 .hero-slider.full-slider {
      height: 100vh;
      /* min-height: 100%; */
  }

  .chef-text {
      width: 21rem;
      line-height: 36px;
  }

  .big-banner {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #b02940;
      height: 100%;
      width: 100%;
      background-attachment: unset;
      background-repeat: no-repeat;
      background-size: 115%;
      background-position: center;
  }

  .big-banner.bg-product {
      position: absolute;
      left: 0;
      background-attachment: unset !important;
      top: 0;
      height: 100%;
      width: 100%;
      background-position: left !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
  }

  .hero-slider .container {
      min-height: 840px;
      display: flex;
      align-items: center;
      text-align: center;
      position: relative;
      z-index: 9;
      justify-content: center;
  }

  .hero-slider .container .product-t {
      width: 400px;
      margin-top: -40px;
      margin-right: -33rem !important;
  }

  .hero-slider .container .product-t h1 {
      font-family: 'Courgette';
      /* color: white; */
  }

  .hero-slider .hero-overlay {
      z-index: 1;
  }

  .hero-overlay {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: #1D1A25;
      opacity: 0.7;
  }

  .hero-title {
      margin: 30px 0 100px 0;
      font-weight: 400;
      font-size: 60px;
      line-height: 70px;
      color: #ffffff;
  }

  .hero-top-slider {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
  }

  .hero-top-slider .swiper-slide .big-banner {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 7s ease-in-out;
      -moz-transition: all 7s ease-in-out;
      -ms-transition: all 7s ease-in-out;
      -o-transition: all 7s ease-in-out;
      transition: all 7s ease-in-out;
  }

  .hero-top-slider .swiper-slide.swiper-slide-active .big-banner {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-transition: all 7s ease-in-out;
      -moz-transition: all 7s ease-in-out;
      -ms-transition: all 7s ease-in-out;
      -o-transition: all 7s ease-in-out;
      transition: all 7s ease-in-out;
  }

  /********************* 7.About Us ***********************/

  .area {
      background: transparent;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
  }

  .circles {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
  }

  .circles li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.7);
      animation: animate 25s linear infinite;
      bottom: -150px;
  }

  .circles li:nth-child(1) {
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
  }

  .circles li:nth-child(2) {
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
  }

  .circles li:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
  }

  .circles li:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
  }

  .circles li:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
  }

  .circles li:nth-child(6) {
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
  }

  .circles li:nth-child(7) {
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
  }

  .circles li:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
  }

  .circles li:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
  }

  .circles li:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
  }

  .about-2img {
      width: 300px;
      height: 300px;
      filter: brightness(0.6);
  }

  .about_images .samll-img {
      text-align: right;
      margin-top: -80px;
      position: relative;
      display: inline-block;
      float: right;
      padding-right: 15px;
  }

  .about_images .samll-img:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      top: -15px;
      left: -15px;
  }

  .about_images>img {
      padding-right: 100px;
  }

  .about_images .samll-img img {
      /* z-index: 1; */
      position: relative;
  }

  .about_images .samll-img .hover-border-bottom {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      bottom: -15px;
      right: 0;
      border: 1px solid #616F7D;
      background-color: transparent;
      width: calc(100% - 30px);
  }

  .samll-img .small-video {
      padding: 20px;
      background-color: white;
      border: 1px solid;
  }

  .about-service {
      padding: 40px 0 0 0;
  }

  .about-service li:not(:last-child) {
      margin-bottom: 40px;
  }

  .about-service li img {
      margin-right: 30px;
  }

  .extra-about h3 {
      margin-bottom: 15px;
  }

  /********************* 8. Coutner ****************/
  .counter-section {
      padding: 70px 0;
  }

  .c_item {
      font-weight: 400;
      font-size: 72px;
      line-height: 72px;
      color: #cf2f49;
      margin-bottom: 10px;
  }

  .counter {
      z-index: 1;
      position: relative;
  }

  .c_desc {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
  }

  /********************** 9. Menu Section ********************/
  .menu-list-item-6 {
      text-align: center;
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 30px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      display: block;
  }

  .food_item_desc-6 {
      position: absolute;
      bottom: -10px;
      margin: 0 auto;
      text-align: center;
      left: 0;
      right: 0;
      color: #ffffff;
      z-index: 1;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }

  .menu-list-item-6:hover .food_item_desc-6 {
      bottom: 30px;
  }

  .food_item_desc-6 .food-name {
      color: #ffffff;
  }

  .menu-list-item-6:hover .food-name {
      color: #b04943;
  }

  .single-pagebtn {
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
  }

  .single-pagebtn svg path {
      fill: #ffffff;
  }

  .menu-list-item-6:hover .single-pagebtn {
      opacity: 1;
      visibility: visible;
  }

  .menu-list-item-6:hover .single-pagebtn svg path {
      fill: #b04943;
  }

  .food-img-price-6 img {
      width: 100%;
      height: auto;
  }

  .food-img-price-6 img,
  .food-img-price-6 {
      border-radius: 20px;
      height: 15rem;
      /* width: 30vw; */
  }

  .food-img-price-6 {
      position: relative;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
  }

  .food-img-price-6:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      border-radius: 20px;
      left: 0;

      background: linear-gradient(180deg, rgba(29, 26, 37, 0) 0%, rgba(29, 26, 37, 0.85) 100%);
  }

  .menu-list-item-6:hover .food-img-price-6:before {
      background: linear-gradient(180deg, rgba(29, 26, 37, 0) -100%, rgba(29, 26, 37, 0.85) 100%);
  }

  /******************** 10. Reservation **************/
  .book-now {
      padding: 70px;
      background-color: #1D1A25;
      z-index: 1;
      position: relative;
  }

  .reservation-section .big-banner {
      height: calc(100% - 100px);
      top: 50px;
  }

  .custom-field {
      padding: 10px;
      border: 1px solid #616F7D;
      margin-bottom: 30px;
  }

  .custom-field-icon {
      color: #616F7D;
      font-size: 24px;
      border-right: 1px solid #616F7D;
      padding-right: 10px;
      line-height: 34px;
  }

  .custom-form-control {
      background-color: transparent;
      padding: 0 0 0 10px;
      color: #616F7D;
      font-size: 18px;
      line-height: 18px;
      border: none;
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
  }

  .book-now-btn {
      margin-top: 10px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent no-repeat;
      bottom: 0;
      color: transparent;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
  }

  /*********************** 11. Service *********************/
  .service-icon {
      margin: 0 auto;
      display: inline-block;
      position: relative;
  }

  .service-item {
      padding: 40px 20px;
      text-align: center;
      background-color: #FFFFFF;
      position: relative;
      cursor: pointer;
      border: 1px solid #616F7D;
  }

  .service-title {
      margin: 30px 0 20px 0;
  }

  .service-item:hover .service-title {
      color: #b04943;
  }

  .hover-border-box {
      z-index: -1;
      height: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      border: 1px solid;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      box-shadow: 10px 10px 30px rgba(29, 26, 37, 0.1);
  }

  .service-item:hover .hover-border-box {
      right: -10px;
      bottom: -10px;
      visibility: visible;
      opacity: 1;
  }

  .service-icon .img-1 {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }

  .service-icon .img-2 {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
  }

  .service-item:hover .service-icon .img-1 {
      opacity: 0;
      visibility: hidden;
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
  }

  .service-item:hover .service-icon .img-2 {
      opacity: 1;
      visibility: visible;
      -webkit-transform: rotateY(0);
      -moz-transform: rotateY(0);
      -ms-transform: rotateY(0);
      transform: rotateY(0);
  }

  /****************** 12. Gallery ******************/
  .gal-img {
      max-width: 350px;
      min-width: 200px;
      min-height: 250px;
      height: 35vw;
      width: 100%;
      max-height: 500px;
      object-fit: cover;
      object-position: center;
  }

  .bg-black {
      background-color: #1D1A25;
  }

  .gallery-item {
      position: relative;
      overflow: hidden;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
  }

  .gallery-content {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      transform: -webkit-rotate3d(1, 0, 0, 90deg);
      transform: -moz-rotate3d(1, 0, 0, 90deg);
      transform: -ms-rotate3d(1, 0, 0, 90deg);
      transform: rotate3d(1, 0, 0, 90deg);
      background-color: rgba(255, 255, 255, 0.8);
  }

  .gallery-item:hover .gallery-content {
      opacity: 1;
  }

  .item-name {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      font-family: 'Courgette', cursive;
      color: #1D1A25;
      margin-bottom: 60px;
  }

  .gallery-content .ri-zoom-in-line {
      font-size: 24px;
      color: #1D1A25;
  }

  .gallery-slider {
      padding: 0 30px;
      overflow: hidden;
  }

  .gallery-item.odd {
      margin-top: 30px;
  }

  .gallery-item:hover img {
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
  }

  .in-top .gallery-content {
      transform-origin: 50% 0%;
      -webkit-animation: in-top 300ms ease 0ms 1 forwards;
      animation: in-top 300ms ease 0ms 1 forwards;
  }

  .in-right .gallery-content {
      transform-origin: 100% 0%;
      -webkit-animation: in-right 300ms ease 0ms 1 forwards;
      animation: in-right 300ms ease 0ms 1 forwards;
  }

  .in-bottom .gallery-content {
      transform-origin: 50% 100%;
      -webkit-animation: in-bottom 300ms ease 0ms 1 forwards;
      animation: in-bottom 300ms ease 0ms 1 forwards;
  }

  .in-left .gallery-content {
      transform-origin: 0% 0%;
      -webkit-animation: in-left 300ms ease 0ms 1 forwards;
      animation: in-left 300ms ease 0ms 1 forwards;
  }

  .out-top .gallery-content {
      transform-origin: 50% 0%;
      -webkit-animation: out-top 300ms ease 0ms 1 forwards;
      animation: out-top 300ms ease 0ms 1 forwards;
  }

  .out-right .gallery-content {
      transform-origin: 100% 50%;
      -webkit-animation: out-right 300ms ease 0ms 1 forwards;
      animation: out-right 300ms ease 0ms 1 forwards;
  }

  .out-bottom .gallery-content {
      transform-origin: 50% 100%;
      -webkit-animation: out-bottom 300ms ease 0ms 1 forwards;
      animation: out-bottom 300ms ease 0ms 1 forwards;
  }

  .out-left .gallery-content {
      transform-origin: 0% 0%;
      -webkit-animation: out-left 300ms ease 0ms 1 forwards;
      animation: out-left 300ms ease 0ms 1 forwards;
  }

  .modal {
      background: rgba(0, 0, 0, 0.49);
  }

  .modal-dialog {
      max-width: max-content;
  }

  .modal-body {
      padding: 5px;
  }

  .modal-content .btn-close {
      position: absolute;
      color: #fff;
      z-index: 999;
      backdrop-filter: brightness(100);
      border-radius: 100%;
      opacity: 1;
      padding: 13px;
      right: -150px;
      background-color: #fff;
      top: -100px;
  }

  /****************** 13. Chef slider ********************/
  .chef-image img {
      border-radius: 100%;
  }

  .chef-name {
      margin: 90px 0 20px 0;
  }

  .chef-image {
      display: inline-block;
      position: relative;
  }

  .readmore-btn {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      text-align: center;
      line-height: 100px;
      display: inline-block;
      background-color: #FFFFFF;
      border: 1px solid #b04943;
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      z-index: 1;
      overflow: hidden;
      margin: 0 auto;
  }

  .hover-border-box-2 {
      z-index: -1;
      height: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      border: 1px solid;
      opacity: 0;
      visibility: hidden;
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
  }

  .chef-image:hover .hover-border-box-2 {
      bottom: -10px;
      opacity: 1;
      visibility: visible;
  }

  .readmore-btn:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 100%;
      z-index: -1;
      background-color: #b04943;
      transition: all 0.3s ease-in-out;
  }

  .chef-image:hover .readmore-btn:after {
      height: 100%;
      bottom: 0;
      visibility: visible;
      opacity: 1;
      border-radius: 100%;
  }

  .chef-image:hover .readmore-btn svg path {
      fill: #ffffff;
  }

  .chef-slider {
      overflow: hidden;
  }

  .swiper-pagination {
      position: relative;
      text-align: center;
      bottom: 0;
      margin-top: 60px;
  }

  .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 15px !important;
      opacity: 1;
      background-color: #616F7D;
  }

  .swiper-pagination-bullet-active {
      outline: 2px solid #b04943;
      outline-offset: 5px;
  }

  .chef-item.layout-2 {
      -webkit-perspective: 1000px;
      -moz-perspective: 1000px;
      margin-bottom: 100px;
      display: inline-block;
  }

  .chef-image.layout-2 img {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-perspective: inherit;
      perspective: inherit;
      -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transition: -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.38);
      transition: -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transition: transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transition: transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3), -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
  }

  .chef-item:hover .chef-image.layout-2 img {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
  }

  .chef-name-designation {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-perspective: inherit;
      perspective: inherit;
      -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transition: -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.38);
      transition: -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transition: transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transition: transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3), -webkit-transform 0.8s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      border-radius: 100%;
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background-color: #1D1A25;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
  }

  .chef-item:hover .chef-name-designation {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }

  .master-chef-social li {
      display: inline-block;
      padding: 0 4px;
  }

  .master-chef-social li a i {
      font-size: 20px;
  }

  .master-chef-social {
      padding: 15px 0 0 0;
  }

  .master-chef-social li a i:hover {
      color: #ffffff;
  }

  /******************* 14. Blog ********************/
  .blog-section {
      padding: 100px 0 250px 0;
  }

  .blog-section.home-blogs {
      padding: 50px 0 211px 0;
  }

  .blog_slider_section {
      padding: 30px 30px 0 30px;
      background-color: #ffffff;
      position: relative;
      z-index: 1;
      margin-top: -170px;
  }

  .blog-slider {
      overflow: hidden;
  }

  .blog-info {
      display: inline-block;
      vertical-align: middle;
      margin-right: 40px;
  }

  .blog-info span {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      display: inline-block;
      vertical-align: middle;
  }

  .blog-info span i {
      font-size: 20px;
      color: #616F7D;
      line-height: 20px;
      margin-right: 15px;
  }

  .blog-top {
      margin: 15px 0 40px 0;
  }

  .blog-title {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 20px;
  }

  .blog-image {
      position: relative;
  }

  .h-250 {
      height: 250px !important;
  }

  .blog-item .readmore-btn {
      right: 40px;
      left: auto;
      width: 80px;
      height: 80px;
      line-height: 80px;
      bottom: -40px;
      z-index: 2;
  }

  .hover-left.readmore-btn:after {
      top: 0;
      height: 100%;
      width: 0;
      left: auto;
      right: 0;
  }

  .hover-left.readmore-btn:hover:after {
      right: auto;
      left: 0;
      opacity: 1;
      visibility: visible;
      width: 100%;
      border-radius: 100%;
  }

  .hover-left.readmore-btn:hover svg path {
      fill: #ffffff;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
      content: none;
  }

  .swiper-button-next,
  .swiper-button-prev {
      width: 60px;
      height: 60px;
      border: 1px solid #616F7D;
      border-radius: 100px;
      text-align: center;
      line-height: 58px;
      top: 250px;
      background-color: #ffffff;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
      background-color: #b04943;
      border-color: #b04943;
  }

  .swiper-button-next i,
  .swiper-button-prev i {
      font-size: 24px;
      color: #1D1A25;
  }

  .swiper-button-next:hover i,
  .swiper-button-prev:hover i {
      color: #ffffff;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
      left: -60px;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
      right: -60px;
  }

  /****************** 15. Subscribe-Form ****************/
  .subscribe-btn {
      width: 60px;
      height: 60px;
      border-radius: 100px;
      text-align: center;
      border: none;
      z-index: 1;
      padding: 0;
      position: relative;
      background-color: #b04943;
      min-width: 60px;
      margin-left: 40px;
  }

  .subscribe-btn:hover {
      background-color: #ffffff;
  }

  .subscribe-btn:hover svg path {
      fill: #b04943;
  }

  .newsletter-form .input-group {
      padding-bottom: 18px;
      padding-right: 40px;
      border-bottom: 2px solid #FFFFFF;
  }

  .subscribe-icon i {
      font-size: 24px;
      margin-right: 15px;
      color: #ffffff;
  }

  .subscribe-control {
      background-color: transparent;
      border: none;
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      padding: 0;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
  }

  .subscribe-control::placeholder {
      color: #ffffff;
  }

  /****************** 16. Testimonials ****************/
  .testimonial-section {
      padding: 140px 0;
      z-index: 1;
  }

  .bg-testimonial {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      z-index: -1;
      background-repeat: no-repeat;
  }

  .testimonial-round {
      max-width: 600px;
      border-radius: 100%;
      background-color: #ffffff;
      padding: 80px;
      transform: scale(1.3);
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0px 0px 40px 5px rgba(29, 26, 37, 0.1);
  }

  .t-item {
      text-align: center;
  }

  .client-video {
      height: 200px;
      width: 100%;
      /* padding: 20px; */
      object-fit: cover;
      border-radius: 39px;
  }

  .client-name2 {
      margin: 0px 0 10px 0;
  }

  .client-image {
      height: 150px;
      width: 150px;
      border-radius: 50%;
  }

  .client-name {
      margin: 40px 0 30px 0;
  }


  /******************* 17. Footer ****************/
  .footer {
      position: relative;
      z-index: 1;
      background-color: rgb(243, 173, 87);
      ;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      padding: 100px 0 160px 0;
  }


  .footer-content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .social-share {
      padding: 0;
  }

  .social-share li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
  }

  .s-icons {
      display: block;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.2);
  }

  .s-icons {
      color: #ffffff;
      line-height: 41px;
      font-size: 24px;
  }

  .f-icons:hover {
      background-color: wheat !important;
      color: #b02940 !important;
  }

  .f-icons:hover a {
      color: #b02940 !important;
  }

  .bg-gray {
      color: #616F7D;
      background-color: rgba(97, 111, 125, 0.2);
  }

  .footer-desc {
      font-size: 18px;
      line-height: 30px;
      color: #ffffff;
      margin: 30px 0;
  }

  .conatct-detail span {
      font-size: 18px;
      line-height: 18px;
      color: #ffffff;
      display: inline-block;
      vertical-align: middle;
  }

  .conatct-detail i {
      margin-right: 20px;
      color: #ffffff;
      font-size: 24px;
      display: inline-block;
      vertical-align: middle;
  }

  .conatct-detail {
      margin-right: 100px;
      display: inline-block;
      vertical-align: middle;
  }

  .copyright-text {
      font-size: 18px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 60px;
  }

  .open-detail {
      padding: 30px;
      position: absolute;
      top: -150px;
      right: 15px;
  }

  .open-detail:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(254, 160, 47, 0.85);
  }

  .footer-time-detail {
      border: 2px solid #FFFFFF;
      padding: 26px;
      text-align: center;
      z-index: 1;
      position: relative;
  }

  .footer-time-detail h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 20px;
  }

  .schedule {
      padding: 0;
      margin-bottom: 40px;
  }

  .schedule li {
      font-weight: 500;
      font-size: 20px;
      color: #1D1A25;
      margin-bottom: 20px;
      line-height: 20px;
  }

  .schedule li span {
      color: #ffffff;
      margin-right: 20px;
  }

  .footer-time-detail a {
      font-weight: 500;
      font-size: 30px;
      line-height: 30px;
      color: #1D1A25;
      text-align: center;
  }

  .footer .container {
      position: relative;
  }

  .map iframe {
      border: 0;
      margin-bottom: -7px;
      width: 100%;
      height: 670px;
      max-width: 100%;
  }

  /**************** 18. scroll to top button **********/

  .scroll-top.active {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
  }

  .scroll-top {
      position: fixed;
      right: 25px;
      bottom: 85px;
      height: 46px;
      width: 46px;
      cursor: pointer;
      display: block;
      background: transparent;
      border-radius: 50px;
      -webkit-box-shadow: inset 0 0 0 2px #fea02f3d;
      box-shadow: inset 0 0 0 2px #fea02f3d;
      z-index: 10000;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(15px);
      -ms-transform: translateY(15px);
      transform: translateY(15px);
      -webkit-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;
  }

  .scroll-top::after {
      position: absolute;
      font-family: 'remixicon' !important;
      content: "\ea78";
      text-align: center;
      line-height: 46px;
      font-size: 24px;
      color: #b04943;
      left: 0;
      top: 0;
      height: 46px;
      width: 46px;
      cursor: pointer;
      display: block;
      z-index: 2;
      -webkit-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;
  }

  .scroll-top svg.border-circle path {
      stroke: #b04943;
      stroke-width: 4;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      -o-transition: all 200ms linear;
      transition: all 200ms linear;
  }

  .scroll-top svg path {
      fill: none;
  }

  /********************** 19. Inner Page Title ***************/
  .page-title.container {
      min-height: 540px !important;
  }

  .page-desc {
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 20px;
  }

  .page_breadcrumb {
      margin-top: -35px;
      position: relative;
      max-width: fit-content;
      z-index: 1;
      margin-left: auto;
      margin-right: auto;
  }

  .page_breadcrumb .breadcrumb {
      justify-content: center;
      padding: 23px 40px;
      background-color: #FFFFFF;
      box-shadow: 10px 10px 30px rgba(29, 26, 37, 0.1);
      border-radius: 35px;
      align-items: center;
  }

  .breadcrumb-item a,
  .breadcrumb-item a i,
  .breadcrumb li {
      display: inline-block;
      vertical-align: middle;
  }

  .breadcrumb-item a {
      color: #616F7D;
  }

  .breadcrumb-item a i {
      font-size: 24px;
      margin-right: 6px;
      margin-top: -4px;
  }

  .breadcrumb li {
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #616F7D;
  }

  .breadcrumb-item a:hover {
      color: #b04943;
  }

  .breadcrumb-item.active {
      color: #1D1A25;
  }

  .breadcrumb-item+.breadcrumb-item::before {
      content: "-";
      color: #1D1A25;
  }

  .page_breadcrumb {
      padding: 0 15px;
  }

  /********************* 20. Pagination ****************/
  .pagiantion-div {
      margin-top: 30px;
  }

  .page-link {
      height: 60px;
      width: 60px;
      border: 1px solid #616F7D;
      padding: 0;
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 58px;
      color: #1D1A25;
      border-radius: 0 !important;
  }

  .page-item:not(:first-child) .page-link {
      margin-left: 0;
  }

  .page-item {
      margin: 0 8px;
      display: inline-block;
      vertical-align: middle;

  }

  .page-link:hover,
  .page-link:focus {
      color: #b04943;
      outline: none;
      box-shadow: none;
      border: 1px solid #616F7D;
      background-color: #1D1A25;
  }

  .pagination {
      display: block;
      text-align: center;
  }

  /******************* 21. Comment Section *****************/
  .comment .thumbnail img {
      border-radius: 100%;
  }

  .content {
      padding-left: 30px;
  }

  .content .title {
      color: #1D1A25;
      margin-bottom: 15px;
      font-family: 'Outifit', sans-serif;
  }

  .content p {
      font-size: 18px;
      line-height: 24px;
  }

  .comment-date span {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: middle;
  }

  .date-dash {
      padding: 0 10px;
  }

  .reply-btn {
      background-color: transparent;
      border: none;
      font-weight: 400;
      font-size: 16px;
      padding: 0;
      line-height: 16px;
      color: #b04943;
  }

  .reply-btn svg {
      margin-right: 10px;
  }

  .comment {
      border-top: 2px solid #F5F5F5;
      padding: 20px 0;
  }

  .comment:first-child {
      padding-top: 0;
      border-top: 0;
  }

  .comment-reply {
      margin-left: 130px;
  }

  .reply-btn:hover {
      color: #1D1A25;
  }

  .reply-btn:hover svg path {
      fill: #1D1A25;
  }

  .comment-form {
      padding-top: 30px;
  }

  .comment-icon {
      padding: 0 0 18px 0;
      display: flex;
      margin-bottom: 40px;
      border-bottom: 2px solid #616F7D;
  }

  .comment-icon i {
      color: #616F7D;
      font-size: 24px;
      margin-right: 15px;
  }

  .comment-form-control {
      border: none;
      padding: 0;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
  }

  .leave-comment {
      margin-top: 40px;
  }

  .comment-form-control.checkbox {
      width: 24px;
      height: 24px;
      border: 2px solid #616F7D;
      background-color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      box-shadow: inset 0 0 0 2px #616f7d;
  }

  .comment-btn .book-btn:after {
      background-color: #1D1A25;
  }

  .comment-btn .book-btn:hover {
      color: #ffffff;
  }

  .comment-btn {
      margin-top: 40px;
  }

  /******************** 22. Contact US page *****************/
  .form-title {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 15px;
  }

  .contact-us .section_heading {
      margin-bottom: 30px;
  }

  .contact-infromation h2 {
      margin-bottom: 30px;
  }

  .hours-info {
      margin-top: 60px;
  }

  .hours-info h3 {
      margin-bottom: 20px;
  }

  .hours-info ul {
      padding: 0;
  }

  .hours-info ul li {
      padding: 20px 0;
      font-size: 20px;
      line-height: 20px;
      border-bottom: 2px solid #F5F5F5;
  }

  .hours-info ul li:last-child {
      border-bottom: none;
      padding-bottom: 0;

  }

  .hours-info ul li span:first-child {
      font-weight: 500;
  }

  .hours-info p {
      margin: 20px 0 0 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #616F7D;
  }

  .hours-info p i {
      font-size: 24px;
      color: #1D1A25;
      margin-right: 20px;
      display: inline-block;
      vertical-align: middle;
  }

  .location-info h3 {
      margin-bottom: 40px;
  }

  .map img {
      width: 100%;
  }

  /******************** 23. 404 page ****************/
  .page_not_found {
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
  }

  .img-up,
  .img-down {
      right: 0;
      left: 0;
      position: relative;
      margin: 0 auto;
  }

  .img-down {
      bottom: 0;
  }

  .img-up {
      top: -31px;
  }

  .page-content {
      padding: 10px 0 40px 0;
  }

  .page-content h2 {
      margin-bottom: 10px;
  }

  .page-content p {
      margin-bottom: 30px;
  }

  .page-content a {
      padding: 20px 30px;
      background-color: #FEA02F;
      border-radius: 30px;
      color: #1D1A25;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      display: inline-block;
  }

  .page-content a:hover {
      background-color: #1D1A25;
      color: #FEA02F;
  }

  /******************** 24. Preloader ****************/

  /************************ 25. Scrollbar CSS **********************/

  /* Firefox */
  body {
      scrollbar-width: auto;
      scrollbar-color: #FEA02F #d4d4d4;
  }

  /* Chrome, Edge, and Safari */
  body::-webkit-scrollbar {
      width: 6px;
  }

  body::-webkit-scrollbar-track {
      background: #d4d4d4;
  }

  body::-webkit-scrollbar-thumb {
      background-color: #FEA02F;
      border-radius: 10px;
      border: 0px solid #ffffff;
  }



  /* ===================================
New home1 page css
====================================== */

  .main_page .header {
      position: relative;
  }

  .main-header {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent;
      z-index: 16;
      padding: 35px 30px;
  }

  .sticky-logo {
      opacity: 0;
      display: none;
  }

  .main-header.fixed-header .navbar-brand .sticky-logo {
      display: block;
      opacity: 1;
  }

  .main-header .search-icon:hover {
      border: none;
  }

  .main-header.fixed-header .navbar-brand .header-logo {
      display: none;
      opacity: 0;
  }

  .main_page .fixed-header .header-icons:hover {
      border: none;
  }

  .main-header.fixed-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 10px 30px;
      background-color: #ffffff;
      box-shadow: 0 0 22px -4px rgb(0 0 0 / 17%);
      -webkit-animation: fixedheader 600ms ease 0ms 1 forwards;
      animation: fixedheader 600ms ease 0ms 1 forwards;
  }

  .main-header.fixed-header .header-menu-wrap .navbar-nav .nav-link,
  .main-header.fixed-header .header-menu-wrap .dropdown-toggle::after {
      color: #000000;
  }

  .main-header.fixed-header .header-menu-wrap .navbar-nav .nav-link.active,
  .main-header.fixed-header .navbar-nav .nav-item:hover .nav-link,
  .main-header.fixed-header .navbar-nav .nav-link.active.dropdown-toggle::after,
  .main-header.fixed-header .navbar-nav .nav-item:hover .dropdown-toggle::after {
      color: #FEA02F;
  }

  .header-menu-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }

  .header-menu-wrap .header-nav-wrap {
      background-color: transparent;
      border-radius: 0;
  }

  .header-menu-wrap .navbar-nav .nav-link {
      color: #ffffff;
  }

  .header-menu-wrap .cart-icon i {
      color: #FEA02F;
  }

  .header-menu-wrap .cart-icon:hover i {
      color: #ffffff;
  }

  .main-header.fixed-header .header-menu-wrap .navbar-nav .nav-link {
      padding: 10px 0 !important;
  }

  .header-menu-wrap .navbar-nav .nav-link.active,
  .header-menu-wrap .navbar-nav .nav-item:hover .nav-link,
  .header-menu-wrap .navbar-nav .nav-link.active.dropdown-toggle::after,
  .header-menu-wrap .navbar-nav .nav-item:hover .dropdown-toggle::after {
      color: #FEA02F;
  }

  .main-header.fixed-header .header-menu-wrap .search-icon i {
      color: #000000;
  }

  .main-header.fixed-header .header-menu-wrap .cart-icon:hover {
      background-color: #FEA02F;
  }

  .main-header.fixed-header .header-menu-wrap .cart-icon {
      width: 55px;
      height: 55px;
      line-height: 55px;
  }

  .main-header.fixed-header .header-icons i {
      font-size: 22px;
  }

  .header-menu-wrap .dropdown-toggle::after {
      color: #ffffff;
      margin: 0 0 0 6px;
  }

  .header-menu-wrap ul li.nav-item {
      margin: 0 25px;
  }

  .header-menu-wrap .cart-icon {
      background-color: transparent;
      border: 2px solid #FEA02F;
      width: 60px;
      height: 60px;
      line-height: 60px;
  }

  .header-menu-wrap .search-icon {
      background-color: transparent;
      width: 60px;
      height: 60px;
      line-height: 60px;
      display: inline;
      margin-right: 60px;
      margin-left: 25px;
  }

  .header-menu-wrap .header-cart .cartcount {
      right: -5px;
  }

  .header-menu-wrap .search-icon i {
      color: #ffffff;
  }

  .header-menu-wrap .search-icon i:hover,
  .main-header.fixed-header .header-menu-wrap .search-icon i:hover {
      color: #FEA02F;
  }

  .header-menu-wrap .cart-icon:hover {
      background-color: #FEA02F;
  }

  .home-page.hero-slider {
      height: auto;
      min-height: 100vh;
      background-color: #000000;
  }

  .hero-slider .container {
      height: auto;
      min-height: 100vh;
  }

  .slider_shape_img2 {
      position: absolute;
      bottom: 40px;
      left: 40px;
      z-index: 4;
  }

  .slider_shape_img1 {
      position: absolute;
      top: 120px;
      right: 40px;
      z-index: 4;
  }

  .main_page .about_images>img {
      padding-right: 0;
  }

  .main_page .about-btn:hover {
      border-color: #FEA02F;
  }

  .video-play {
      position: absolute;
      top: 50%;
      bottom: auto;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
  }

  .video-play a {
      background-color: #ffffff;
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
  }

  .video-play a:hover {
      background-color: #FEA02F;
      color: #ffffff;
      z-index: 9;
  }

  .video-play a i {
      font-size: 30px;
  }

  .video_button .button-outer-circle {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
  }

  .has-delay-short {
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
  }

  .has-scale-animation {
      -webkit-animation: smallScale 2s infinite;
      animation: smallScale 2s infinite;
  }

  .video_modal .modal-content {
      background-color: transparent;
      border: none;
  }

  .video_modal .modal-header {
      border: none;
  }

  .video_modal .modal-content .btn-close {
      top: 0;
      right: 0;
      padding: 8px;
      border-radius: 25%;
      opacity: 0.8;
  }

  .counter-section2 .counter .c_desc {
      color: #1D1A25;
  }

  .counter-section2 {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
  }

  .menu-background1 {
      position: absolute;
      top: 45px;
      right: 0;
      animation: animationFramesFive 11s infinite linear alternate;
  }

  .menu-background2 {
      position: absolute;
      bottom: 250px;
      left: 0;
      animation: animationFramesFive 11s infinite linear alternate;
  }

  .menu-list-item-5 {
      margin-bottom: 53px;
      display: block;
  }

  .food-img-price-5 img {
      border-radius: 20px;
      width: 100%;
      height: auto;
      -moz-transform: rotate(0) scale(1);
      -ms-transform: rotate(0) scale(1);
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
  }

  .menu-list-item-5:hover .food-img-price-5 img {
      -moz-transform: rotate(10deg) scale(1.2);
      -ms-transform: rotate(10deg) scale(1.2);
      -webkit-transform: rotate(10deg) scale(1.2);
      transform: rotate(10deg) scale(1.2);
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
  }

  .food_item_desc-5 .food-name {
      float: left;
      margin-top: 30px;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
  }

  .menu-list-item-5:hover .food-name {
      color: #FEA02F;
  }

  .food_item_desc-5 .about-food {
      clear: both;
  }

  .f_price-5 {
      margin-top: 30px;
      float: right;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
  }

  .food-img-price-5 {
      border-radius: 20px;
      overflow: hidden;
  }

  .food_menu_section {
      padding-bottom: 40px;
  }

  .menu-list-detail .item-link:hover,
  .menu-list-detail .item-link.active {
      background-color: transparent;
      color: #FEA02F;
  }

  .menu-list-detail .nav-item button {
      padding: 0;
  }

  .menu-list-detail .nav-item span {
      color: #616F7D;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      font-family: Outfit;
      padding-top: 10px;
      display: inline-block;
  }

  .menu-list-detail .nav-item .item-link.active span {
      color: #FEA02F;
  }

  .menu-list-detail .nav-item .item-link:hover span {
      color: #FEA02F;
  }

  .menu-list-detail .nav-item .item-link {
      background-color: transparent;
  }

  .menu-list-detail .nav-item .item-link.active svg path,
  .menu-list-detail .nav-item .item-link:hover svg path {
      fill: #FEA02F;
  }

  .menu-list-detail .nav-item .item-link svg path {
      fill: #616F7D;
  }

  .image-tab {
      height: 100px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-top: 0 !important;
  }

  .menu-list-detail.menu-nav-item li {
      margin: 0 30px;
  }

  .book-reservation .reserved-form-image {
      height: calc(100% - 100px);
      top: 50px;
  }

  .book-reservation .hero-overlay {
      height: calc(100% - 256px);
      top: 50px;
  }

  .reserved-form-image .big-banner {
      height: 530px;
  }

  .book-reservation .book-now h2 {
      font-size: 58px;
  }

  .book-reservation .reservation-data {
      position: relative;
      top: 150px;
  }

  .reservation-data h2,
  .reservation-data p {
      color: #ffffff;
  }

  .reservation-data h2 {
      padding-bottom: 20px;
  }

  .reservation-section .sticky-stick {
      position: absolute;
      bottom: 25px;
      top: auto;
      left: 195px;
      right: 0;
      animation: swing ease-in-out 1s infinite alternate;
      transform-origin: center -20px;
  }

  .services_section .service-item {
      margin-bottom: 30px;
  }

  .services_section {
      padding: 100px 0 70px 0;
  }

  .services_section .service-icon {
      display: flex;
      align-items: center;
  }

  .services_section .service-item {
      padding: 40px;
  }

  .services_section .service-catelog {
      border: 1px solid #616F7D;
      border-radius: 50%;
      padding: 20px;
      margin-right: 30px;
  }

  .services_section .service_desc {
      text-align: left;
      padding-top: 25px;
  }

  .services_section .service-item:hover .service-catelog {
      background-color: #FEA02F;
      border-color: #FEA02F;
  }

  .services_section .service-item:hover svg path {
      fill: #ffffff;
  }

  .services_section .service-item:hover {
      border: 1px solid #FEA02F;
      box-shadow: 10px 10px 30px rgba(29, 26, 37, 0.1);
  }

  .services_section .service-item:hover h3 {
      color: initial;
  }

  .chef-background {
      position: absolute;
      top: auto;
      bottom: 15px;
  }

  .master-chef .chef-image img {
      border-radius: 0px;
  }

  .master-chef .chef-image .social-ic-link {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 2;
      text-align: center;
      opacity: 1;
      visibility: visible;
      transition: all ease .4s;
  }

  .master-chef .chef-image .social-ic-link .ic-toggle,
  .master-chef .chef-image .social-ic-link a {
      background-color: #FEA02F;
      width: 40px;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 18px;
      margin: 0px;
      color: #ffffff;
  }

  .master-chef .chef-image .social-ic-link .ic-toggle i,
  .master-chef .chef-image .social-ic-link li a i {
      font-size: 24px;
  }

  .master-chef .chef-image .social-ic-link li {
      display: block;
      margin: 0;
  }

  .master-chef .chef-image .social-ic-link li a {
      background-color: #ffffff;
      color: #000000;
      margin-bottom: 10px;
  }

  .master-chef .chef-image .social-ic-link li a:hover {
      background-color: #ffffff;
      color: #FEA02F;
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
  }

  .master-chef .chef-image .social-ic-link .ic-toggle.active .ri-add-line:before {
      content: "\eb99";
  }

  .master-chef .chef-image .social-ic-link:hover ul {
      opacity: 1;
      margin-bottom: 0;
      visibility: visible;
  }

  .master-chef .chef-image .social-ic-link:hover .ri-add-line:before {
      content: "\eb99";
  }

  .master-chef .chef-image .social-ic-link ul {
      opacity: 0;
      visibility: hidden;
      transition: all ease .4s;
      margin-bottom: 40px;
      padding: 0;
  }

  .master-chef .chef-image .social-ic-link .toggle-menu-social.active {
      opacity: 1;
      margin-bottom: 0;
      visibility: visible;
  }

  .master-chef .chef-item .chef-name {
      margin: 40px 0 20px 0;
  }

  /*shine effect*/
  .master-chef .chef-image {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      background-color: #454545;
  }

  .master-chef .chef-image img {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      width: 100%;
  }

  .master-chef .chef-image:hover img {
      -webkit-transform: scale(1.07);
      -moz-transform: scale(1.07);
      -ms-transform: scale(1.07);
      -o-transform: scale(1.07);
      transform: scale(1.07);
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      opacity: 0.7;
  }

  .master-chef .chef-image::before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg);
  }

  .master-chef .chef-image:hover::before {
      -webkit-animation: shine .75s;
      animation: shine .75s;
      z-index: 1;
  }

  .blog_section .blog-image {
      overflow: hidden;
      position: relative;
  }

  .hover-shine::before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg);
  }

  .blog-slider-swipe .blog-item .blog-image:hover .hover-shine::before {
      -webkit-animation: shine .75s;
      animation: shine .75s;
  }

  .blog-slider-swipe {
      overflow: hidden;
  }

  .blog-slider-swipe .blog-top {
      margin: 15px 0 20px 0;
  }

  .blog-slider-swipe .blog-title {
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
  }

  .blog-slider-swipe .blog-list-desc {
      padding-bottom: 20px;
  }

  .blog-slider-swipe .button_with_text {
      color: #616F7D;
  }

  .blog-slider-swipe .blog-info span {
      font-size: 18px;
      line-height: 18px;
  }

  .blog-slider-swipe .blog-info span i {
      margin-right: 10px;
  }

  .blog-slider-swipe .button_with_text:hover {
      color: #FEA02F;
  }

  .blog-slider-swipe .button_with_text:hover svg path {
      fill: #FEA02F;
  }

  .blog-slider-swipe .button_with_text svg {
      margin-left: 15px;
  }

  .testimonial-background {
      position: absolute;
      top: 0;
      left: 0;
  }

  .swiper-testimonials .testimonial-shape {
      background: #FFFFFF;
      box-shadow: 10px 10px 30px rgba(29, 26, 37, 0.1);
      border-radius: 30px;
      margin-top: 60px;
      padding: 40px 40px 130px 40px;
  }

  .swiper-testimonials .client-image {
      width: auto;
      height: auto;
      margin-top: 60px;
  }

  .swiper-testimonials .testimonial-slider {
      position: relative;
  }

  .swiper-testimonials .swiper-pagination {
      bottom: 115px;
      width: auto;
      display: inline-block;
      left: 40px;
      margin-top: 26px;
  }

  .product-icons {
      position: relative;
      z-index: 99;
  }

  .product-icons::after {
      position: absolute;
      content: '';
      z-index: -1;
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      background-color: #a3643f;
      border-radius: 50%;
  }